import { useState } from "react";

export const MonthPicker = ({ selectedMonth, onClick }) => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const [menu, setMenu] = useState(false);

  return (
    <>
      <select onClick={() => setMenu(true)}>
        <option hidden>{selectedMonth}</option>
      </select>

      {
        menu ? (
          <div className="date_menu month">
            {months.map((month,index) => (
              <div className={selectedMonth === month ? 'selected_year' : null} key={index} onClick={()=>{onClick(index+1); setMenu(false)}}>
                {month}
              </div>
      ))}
          </div>
        ) : null
      }
    </>
  );
};