import { useEffect } from "react";
import { Lang } from "./Lang";

export const FirstItems = () => {
    if(!localStorage.getItem('lang')){
        localStorage.setItem('lang', 'ru');
    };

    if(window.location.href.includes('http://') && !window.location.href.includes('localhost:300')){
        window.location.href = 'https://big-voyage.am'+window.location.pathname;
    };

    // Google Tag Manager
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MNKGTW7L');
    // End Google Tag Manager

    useEffect(()=>{
        document.title = Lang({text: 'site_title'});
        const metaTag = document.querySelector('meta[property="og:title"]');
        metaTag.content = Lang({text: 'site_title'});
        const metaTag2 = document.querySelector('meta[itemprop="name"]');
        metaTag2.content = Lang({text: 'site_title'});
    }, [localStorage.getItem('lang')]);
};