import '../assets/css/AboutUs/aboutUs.css';

export const AboutUs = () => {
    return (
        <>
            <div className="container mt-10">
              Big Voyage - это команда профессионалов, готовых сделать ваше путешествие по Армении и Грузии незабываемым и удивительным. Мы стремимся предоставить нашим клиентам высококачественный сервис и уникальный опыт путешествия, и наша цель - сделать каждое ваше приключение с нами незабываемым.
              <div className="mt-5">
                <h2>Наши ценности</h2>
                <hr />
                <ul>
                    <li>Профессионализм: Мы имеем богатый опыт работы в сфере туризма и готовы предоставить вам высококачественные услуги и индивидуальный подход.</li>
                    <li>Качество: Мы стремимся к постоянному совершенствованию и предлагаем только лучшие услуги и маршруты для наших клиентов.</li>
                    <li>Гостеприимство: Мы ценим каждого нашего гостя и готовы сделать все возможное, чтобы ваше путешествие было комфортным и приятным.</li>
                    <li>Инновации: Мы следим за последними туристическими трендами и постоянно внедряем новые идеи и инновации для улучшения наших услуг.</li>
                </ul>
                Мы гордимся тем, что помогаем нашим клиентам открыть для себя красоту и уникальность Армении , и мы с нетерпением ждем возможности поделиться с вами нашими путешествиями и впечатлениями.
              </div>
            </div>
        </>
    );
};