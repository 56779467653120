import { useState, useEffect, useRef } from 'react';
import { YearPicker } from './YearPicker';
import { MonthPicker } from './MonthPicker';
import { DayPicker } from './DayPicker';
import { HourPicker } from './HourPicker';
import { MinutePicker } from './MinutePicker';
import { FaCaretDown } from "react-icons/fa";
import '../../assets/css/Calendar/calendar.css';

export const Calendar = ({setState}) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()+1);
  const [selectedDay, setSelectedDay] = useState(new Date().getDate());
  const [selectedHour, setSelectedHour] = useState(new Date().getHours());
  const [selectedMinute, setSelectedMinute] = useState(Math.ceil(new Date().getMinutes() / 5) * 5);
  const [daysInMonth, setDaysInMonth] = useState(new Date(selectedYear, selectedMonth, 0).getDate());
  const [isOpen, setIsOpen] = useState(false);
  const calendarRef = useRef(null);
  const outsideRef = useRef(null);
  const currentYear = new Date().getFullYear();

  useEffect(()=>{
    setState({
      year: selectedYear,
      month: selectedMonth,
      day: selectedDay,
      hour: selectedHour,
      minute: selectedMinute === 60 || selectedMinute === 0 ? '00' : selectedMinute === 5 ? '05' : selectedMinute
    });
  }, [selectedYear,selectedMonth,selectedDay,selectedHour,selectedMinute]);

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const months = [
    { name: 'January', days: 31 },
    { name: 'February', days: isLeapYear(currentYear) ? 29 : 28 },
    { name: 'March', days: 31 },
    { name: 'April', days: 30 },
    { name: 'May', days: 31 },
    { name: 'June', days: 30 },
    { name: 'July', days: 31 },
    { name: 'August', days: 31 },
    { name: 'September', days: 30 },
    { name: 'October', days: 31 },
    { name: 'November', days: 30 },
    { name: 'December', days: 31 }
  ];

  useEffect(() => {
    months.map((item,index)=>{
      if(index+1 === selectedMonth){
        setDaysInMonth(item.days);
      }
    });
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (calendarRef.current && !calendarRef.current.contains(e.target) && outsideRef.current && !outsideRef.current.contains(e.target)) {
        if(!e.target.parentElement.classList.contains('date_menu')){
          setIsOpen(false);
        }
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className='my_calendar'>
        <div className='selected_date' ref={outsideRef} onClick={()=>setIsOpen(!isOpen)}>
          {selectedDay}/{selectedMonth}/{selectedYear} - {selectedHour}:{selectedMinute === 60 || selectedMinute === 0 ? '00' : selectedMinute === 5 ? '05' : selectedMinute}
          <div className='date_arrow_down'><FaCaretDown /></div>
        </div>

        {
          isOpen ? (
            <div className='date_calendar' ref={calendarRef}>
              <YearPicker selectedYear={selectedYear} onClick={setSelectedYear} />
              <MonthPicker selectedMonth={selectedMonth} onClick={setSelectedMonth} />
              <DayPicker selectedDay={selectedDay} daysInMonth={daysInMonth} onClick={setSelectedDay} />
              <HourPicker selectedHour={selectedHour} onClick={setSelectedHour} /><span className="keter">:</span>
              <MinutePicker selectedMinute={selectedMinute} onClick={setSelectedMinute} />
              <div className="date_calendar_close" onClick={()=>setIsOpen(false)}>Выбрать</div>
            </div>
          )
        : null
        }
    </div>
  );
};