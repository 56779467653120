import Carousel from 'react-bootstrap/Carousel';
import Accordion from 'react-bootstrap/Accordion';
import { MyAccordion } from '../components/MyAccordion';
import excursion1 from '../assets/images/excursion1.jpg';
import excursion2 from '../assets/images/excursion2.jpg';
import excursion3 from '../assets/images/excursion3.jpg';
import excursion4 from '../assets/images/excursion4.jpg';
import excursion5 from '../assets/images/excursion5.jpg';
import kamar1 from '../assets/images/kamar1.jpg';
import garni_tachar from '../assets/images/garni_tachar.jpg';
import gexard_ekexeci from '../assets/images/gexard_ekexeci.jpg';
import qareri_simfonya1 from '../assets/images/qareri_simfonya1.jpg';
import kamar2 from '../assets/images/kamar2.webp';
import garni_tachar2 from '../assets/images/garni_tachar2.jpg';
import gexard_ekexeci2 from '../assets/images/gexard_ekexeci2.jpg';
import qareri_simfonya2 from '../assets/images/qareri_simfonya2.jpg';
import sevan from '../assets/images/sevan.webp';
import dilijan from '../assets/images/dilijan.jpeg';
import haxarcin from '../assets/images/haxarcin.jpg';
import goshavanq from '../assets/images/goshavanq.jpg';
import xor_virap from '../assets/images/xor_virap.jpeg';
import peshera from '../assets/images/peshera.jpg';
import gini from '../assets/images/gini.jpg';
import noravanq from '../assets/images/noravanq.jpg';
import tatev from '../assets/images/tatev.jpg';
import chopanuxi from '../assets/images/chopanuxi.jpeg';
import xndzoresk from '../assets/images/xndzoresk.jpg';
import shakei_jrvej from '../assets/images/shaki_jrvej.jpeg';
import zvartnots_tachar from '../assets/images/zvartnots_tachar.jpg';
import hripsime from '../assets/images/hripsime.jpg';
import gayane from '../assets/images/gayane.jpg';
import mayr_tachar from '../assets/images/mayr_tachar.jpg';
import amberd from '../assets/images/amberd.jpg';
import saxmosavanq from '../assets/images/saxmosavanq.jpeg';
import ovanavanq from '../assets/images/ovanavanq.jpeg';
import tareri_purak from '../assets/images/tareri_purak.webp';
import haxpat from '../assets/images/haxpat.jpg';
import zarni_parni from '../assets/images/zarni_parni.jpg';
import axtala from '../assets/images/axtala.jpg';
import sanahin from '../assets/images/sanahin.jpg';
import gyumri from '../assets/images/gyumri.webp';
import gyumri2 from '../assets/images/gyumri2.jpg';
import gyumri3 from '../assets/images/gyumri3.jpg';
import konyak1 from '../assets/images/konyak1.jpg';
import konyak2 from '../assets/images/konyak2.jpg';
import konyak3 from '../assets/images/konyak3.jpg';
import '../assets/css/ExcursionDetails/excursionDetail.css';

export const ExcursionDetails = () => {
    const index = localStorage.getItem('excursion_details_page');

    return (
        <>
          {
            index == 1 ? <div className="container mt-10">
                            <Carousel className='excursion_carousel'>
                              <Carousel.Item>
                                <img
                                  className="d-block w-100"
                                  src={excursion1}
                                  alt="First slide"
                                />
                              </Carousel.Item>
                              <Carousel.Item>
                                <img
                                  className="d-block w-100"
                                  src={excursion2}
                                  alt="Second slide"
                                />
                              </Carousel.Item>
                              <Carousel.Item>
                                <img
                                  className="d-block w-100"
                                  src={excursion3}
                                  alt="Third slide"
                                />
                              </Carousel.Item>
                              <Carousel.Item>
                                <img
                                  className="d-block w-100"
                                  src={excursion4}
                                  alt="Third slide"
                                />
                              </Carousel.Item>
                              <Carousel.Item>
                                <img
                                  className="d-block w-100"
                                  src={excursion5}
                                  alt="Third slide"
                                />
                              </Carousel.Item>
                            </Carousel>
            <h5 className='mt-5'>
                Арки Чаренца, Храма Гарни, Ущелья Симфонии Камней, Монастыря Гегард, Мастер-класса по лавашу
            </h5>
            <div className="mt-5">
                Подарите себе незабываемое путешествие по сердцу Армении, где природа и древние памятники сливаются в одно целое. От величественной Арки Чаренца до древнего храма Гарни, от ущелья Симфонии Камней до таинственного монастыря Гегард – каждая остановка обещает удивление и вдохновение. Завершите день на мастер-классе по приготовлению лаваша и унесите с собой не только красивые фотографии, но и ароматные воспоминания об армянской культуре.
            </div>
              <Accordion defaultActiveKey="0" className='mb-5'>
                  <MyAccordion eventKey={'0'} title={'Арка Чаренца'} photo={excursion1} text={`
                      Начнем наше путешествие с Арки Чаренца, созданной в честь великого армянского поэта Егише Чаренца. С этого места открывается потрясающий вид на долину и легендарный Библейский Арарат. Здесь можно погрузиться в размышления и насладиться тишиной и величием природы.
                  `}/>
                  <MyAccordion eventKey={'1'} title={'Храм Гарни'} photo={excursion2} text={`
                      Далее мы отправимся в древнюю языческую святыню - храм Гарни. Этот великолепный памятник эллинистической архитектуры, возведенный в I веке н. э., является уникальным свидетельством древнего языческого поклонения в Армении. Окружающая храм природа создает неповторимую атмосферу для расслабления и медитации.
                  `}/>
                  <MyAccordion eventKey={'2'} title={'Ущелье Симфония Камней'} photo={excursion3} text={`
                      Путешествуя дальше, мы окунемся в чудо природы - ущелье Симфония Камней. Это удивительное место знаменито своими базальтовыми колоннами, которые словно строй оркестра, играют мелодию природы. Здесь можно сделать захватывающие фотографии и насладиться живописными видами.
                  `}/>
                  <MyAccordion eventKey={'3'} title={'Монастырь Гегард'} photo={excursion4} text={`
                      Следующим пунктом нашего маршрута станет знаменитый монастырь Гегард. Построенный в IV веке, этот монастырь частично выдолблен в скале, что делает его уникальным и удивительно красивым. Гегард окутан легендами и мистикой, а его атмосфера наполнена духовностью и умиротворением.
                  `}/>
                  <MyAccordion eventKey={'4'} title={'Мастер-класс по лавашу'} photo={excursion5} text={`
                      Наше путешествие завершится мастер-классом по приготовлению традиционного армянского лаваша. Под руководством опытных местных пекарей вы узнаете все секреты этого древнего кулинарного искусства и сможете испечь свой собственный лаваш. Вкусный, хрустящий и ароматный, он станет достойным завершением нашей экскурсии.
                  `}/>
                  </Accordion>
            </div>
            : index == 2 ? <div className="container mt-10">
              <Carousel className='excursion_carousel'>
                              <Carousel.Item>
                                <img
                                  className="d-block w-100"
                                  src={kamar1}
                                  alt="First slide"
                                />
                              </Carousel.Item>
                              <Carousel.Item>
                                <img
                                  className="d-block w-100"
                                  src={garni_tachar}
                                  alt="Second slide"
                                />
                              </Carousel.Item>
                              <Carousel.Item>
                                <img
                                  className="d-block w-100"
                                  src={qareri_simfonya1}
                                  alt="Third slide"
                                />
                              </Carousel.Item>
                              <Carousel.Item>
                                <img
                                  className="d-block w-100"
                                  src={gexard_ekexeci}
                                  alt="Third slide"
                                />
                              </Carousel.Item>
                              <Carousel.Item>
                                <img
                                  className="d-block w-100"
                                  src={sevan}
                                  alt="Third slide"
                                />
                              </Carousel.Item>
                            </Carousel>
            <h5 className='mt-5'>
                Арка Чаренца,Храм Гарни, Ущелье Симфония Камней, Монастырь Гегард, Озеро Севан и монастырь Севанаванк
            </h5>
            <div className="mt-5">
            Эта увлекательная экскурсия по Армении приглашает вас на захватывающее путешествие через историю, культуру и природные красоты страны. Начиная с потрясающего вида с Арки Чаренца на долину и Арарат, вы сможете ощутить величие природы и уют древних мест. В древнем храме Гарни вы почувствуете древнее языческое наследие Армении, окруженные природой, способствующей внутреннему покою.
            <br /><br />
            Далее ущелье Симфония Камней привлечет вас своими уникальными базальтовыми колоннами, создающими мелодию природы. Посещение монастыря Гегард, частично высеченного в скале, откроет перед вами тайны и духовность средневековой Армении. Завершение путешествия на озере Севан и в монастыре Севанаванк подарит незабываемые виды и глубокие впечатления от культурного и природного наследия Армении, которые останутся с вами на всю жизнь.
            </div>
              <Accordion defaultActiveKey="0" className='mb-5'>
                  <MyAccordion eventKey={'0'} title={'Арка Чаренца'} photo={kamar1} text={`
                      Начнем наше путешествие с Арки Чаренца, созданной в честь великого армянского поэта Егише Чаренца. С этого места открывается потрясающий вид на долину и легендарный Библейский Арарат. Здесь можно погрузиться в размышления и насладиться тишиной и величием природы.
                  `}/>
                  <MyAccordion eventKey={'1'} title={'Храм Гарни'} photo={garni_tachar} text={`
                      Далее мы отправимся в древнюю языческую святыню - храм Гарни. Этот великолепный памятник эллинистической архитектуры, возведенный в I веке н. э., является уникальным свидетельством древнего языческого поклонения в Армении. Окружающая храм природа создает неповторимую атмосферу для расслабления и медитации.
                  `}/>
                  <MyAccordion eventKey={'2'} title={'Ущелье Симфония Камней'} photo={qareri_simfonya1} text={`
                      Путешествуя дальше, мы окунемся в чудо природы - ущелье Симфония Камней. Это удивительное место знаменито своими базальтовыми колоннами, которые словно строй оркестра, играют мелодию природы. Здесь можно сделать захватывающие фотографии и насладиться живописными видами.
                  `}/>
                  <MyAccordion eventKey={'3'} title={'Монастырь Гегард'} photo={gexard_ekexeci} text={`
                      Следующим пунктом нашего маршрута станет знаменитый монастырь Гегард. Построенный в IV веке, этот монастырь частично выдолблен в скале, что делает его уникальным и удивительно красивым. Гегард окутан легендами и мистикой, а его атмосфера наполнена духовностью и умиротворением.
                  `}/>
                  <MyAccordion eventKey={'4'} title={'Озеро Севан и монастырь Севанаванк'} photo={sevan} text={`
                      Завершением нашего увлекательного путешествия станет посещение живописного озера Севан и древнего монастыря Севанаванк. Озеро Севан, величественно раскинувшее свои воды в самом сердце Армении, поражает своей красотой и кристально чистой водой. Монастырь Севанаванк, расположенный на полуострове, представляет собой величественное сооружение IX века, история которого овеяна множеством легенд. Прогулка здесь подарит вам неповторимые впечатления и великолепные виды.
                  `}/>
              </Accordion>
            </div>
            : index == 3 ? <div className="container mt-10">
            <Carousel className='excursion_carousel'>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={sevan}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={dilijan}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={haxarcin}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={goshavanq}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
<h5 className='mt-5'>
Озеро Севан и монастырь Севанаванк, Дилижан, Монастырский комплекс Агарцин, Монастырь Гошаванк
</h5>
<div className="mt-5">
Экскурсия по Северной Армении начинается с посещения кристально чистого озера Севан и древнего монастыря Севанаванк IX века. Затем мы направляемся в живописный Дилижан, "армянскую Швейцарию", славящуюся зелеными лесами и традиционной архитектурой. После этого осматриваем монастырский комплекс Агарцин X-XIII веков, расположенный в лесной долине. Завершаем путешествие в монастыре Гошаванк XII века, основанном ученым Мхитаром Гошем. Наслаждаясь видами и историей, мы погружаемся в атмосферу древности и духовного спокойствия.
</div>
<Accordion defaultActiveKey="0" className='mb-5'>
  <MyAccordion eventKey={'0'} title={'Озеро Севан и монастырь Севанаванк'} photo={sevan} text={`
      Начнем наше увлекательное путешествие с посещения живописного озера Севан и древнего монастыря Севанаванк. Озеро Севан, находящееся на высоте 1900 метров над уровнем моря, поражает своей кристальной чистотой и бескрайними просторами. Монастырь Севанаванк, построенный в IX веке, величественно возвышается на полуострове и рассказывает истории о древних временах. Здесь вы сможете насладиться прекрасными видами и ощутить духовное спокойствие.
  `}/>
  <MyAccordion eventKey={'1'} title={'Дилижан'} photo={dilijan} text={`
      Следующая остановка – живописный городок Дилижан, часто называемый "армянской Швейцарией". Здесь мы сделаем короткую остановку, чтобы прогуляться по узким улочкам этого уютного местечка. Дилижан славится своими зелеными лесами, кристально чистым воздухом и традиционной архитектурой. Не упустите возможность посетить одну из местных ремесленных лавок и приобрести авторские сувениры.
  `}/>
  <MyAccordion eventKey={'2'} title={'Монастырский комплекс Агарцин'} photo={haxarcin} text={`
      После Дилижана наш путь лежит к монастырскому комплексу Агарцин, расположенному в живописной долине лесных холмов. Этот комплекс, построенный в X-XIII веках, представляет собой один из известных культурных и духовных центров Армении. Здесь величественные церкви и каменные резные кресты (хачкары) расскажут вам об истории и традициях армянского народа.
  `}/>
  <MyAccordion eventKey={'3'} title={'Монастырь Гошаванк'} photo={goshavanq} text={`
      Завершим наше путешествие посещением монастыря Гошаванк, который находится неподалеку. Основанный в XII веке знаменитым ученым и писателем Мхитаром Гошем, этот монастырь является не только религиозным, но и образовательным центром своего времени. Великолепные архитектурные сооружения и впечатляющее каменное убранство создают неповторимую атмосферу древности и мудрости.
  `}/>
  </Accordion>
</div>
          : index == 4 ? <div className="container mt-10">
          <Carousel className='excursion_carousel'>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={garni_tachar2}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={kamar2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={qareri_simfonya2}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={gexard_ekexeci2}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={xor_virap}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
<h5 className='mt-5'>
Арка Чаренца, Храм Гарни, Ущелье Симфония Камней, Монастырь Гегард, Монастырь и крепость Хор Вирап
</h5>
<div className="mt-5">
Погрузитесь в уникальную красоту Армении: начните с Арки Чаренца с потрясающим видом на долину и Арарат. Затем посетите древний храм Гарни, окруженный живописной природой. В ущелье Симфония Камней удивитесь базальтовым колоннам, создающим волшебную атмосферу. Отправьтесь к монастырю Гегард, встречающему своим уникальным архитектурным стилем. Завершите экскурсию у монастыря Хор Вирап с видом на гору Арарат и его богатой историей.
</div>
<Accordion defaultActiveKey="0" className='mb-5'>
<MyAccordion eventKey={'0'} title={'Арка Чаренца'} photo={kamar2} text={`
    Начнем наше путешествие с Арки Чаренца, созданной в честь великого армянского поэта Егише Чаренца. С этого места открывается потрясающий вид на долину и легендарный Библейский Арарат. Здесь можно погрузиться в размышления и насладиться тишиной и величием природы.
`}/>
<MyAccordion eventKey={'1'} title={'Храм Гарни'} photo={garni_tachar2} text={`
    Далее мы отправимся в древнюю языческую святыню - храм Гарни. Этот великолепный памятник эллинистической архитектуры, возведенный в I веке н. э., является уникальным свидетельством древнего языческого поклонения в Армении. Окружающая храм природа создает неповторимую атмосферу для расслабления и медитации.
`}/>
<MyAccordion eventKey={'2'} title={'Ущелье Симфония Камней'} photo={qareri_simfonya2} text={`
    Путешествуя дальше, мы окунемся в чудо природы - ущелье Симфония Камней. Это удивительное место знаменито своими базальтовыми колоннами, которые словно строй оркестра, играют мелодию природы. Здесь можно сделать захватывающие фотографии и насладиться живописными видами.
`}/>
<MyAccordion eventKey={'3'} title={'Монастырь Гегард'} photo={gexard_ekexeci2} text={`
    Следующим пунктом нашего маршрута станет знаменитый монастырь Гегард. Построенный в IV веке, этот монастырь частично выдолблен в скале, что делает его уникальным и удивительно красивым. Гегард окутан легендами и мистикой, а его атмосфера наполнена духовностью и умиротворением.
`}/>
<MyAccordion eventKey={'4'} title={'Монастырь и крепость Хор Вирап'} photo={xor_virap} text={`
    Завершим наше путешествие посещением монастыря и крепости Хор Вирап. Этот древний монастырь, расположенный на границе с Турцией, известен своим потрясающим видом на гору Арарат и важным историческим значением. Здесь, по преданию, Святой Григорий Просветитель был заключен в подземелье на 13 лет до того, как он стал первым католикосом всех армян. Монастырь и крепость Хор Вирап символизируют духовность, силу и непоколебимость армянского народа.
`}/>
</Accordion>
</div>
          : index == 5 ? <div className="container mt-10">
          <Carousel className='excursion_carousel'>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={xor_virap}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={peshera}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={gini}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={noravanq}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
<h5 className='mt-5'>
Хор Вирап, Пещера Птиц (Арени-1), Винный завод и дегустация вин, Нораванк
</h5>
<div className="mt-5">
Путешествие начинается с монастыря Хор Вирап с видом на гору Арарат. Затем — Пещера Птиц, где нашли древнейшую в мире винодельню. После экскурсии — тур по винодельческому заводу с дегустацией армянских вин. Завершение дня — монастырь Нораванк с его уникальной архитектурой и духовной атмосферой.
</div>
<Accordion defaultActiveKey="0" className='mb-5'>
<MyAccordion eventKey={'0'} title={'Хор Вирап'} photo={xor_virap} text={`
    Начнем наше путешествие с легендарного монастыря Хор Вирап, расположенного на живописном фоне Араратской долины на границе с Турцией. Монастырь известен своей историей и значением для армянского христианства. Здесь Святой Григорий Просветитель провел 13 лет в заключении, прежде чем стал основателем Армянской апостольской церкви. С вершины холма, где расположен монастырь, открывается захватывающий вид на знаменитую гору Арарат, которую, если повезет с погодой, можно увидеть во всем ее величии.
`}/>
<MyAccordion eventKey={'1'} title={'Пещера Птиц (Арени-1)'} photo={peshera} text={`
    Следующая остановка – загадочная Пещера Птиц, или Арени-1, расположенная неподалеку от одноименной деревни Арени. Эта пещера стала настоящим археологическим открытием, когда ученые нашли здесь древнейшую в мире винодельню, возраст которой насчитывает более 6000 лет. Также были обнаружены различные артефакты, дающие представление о быте и культуре древних поселений. Прогулка по пещере не только перенесет вас в далекую эпоху, но и позволит окунуться в атмосферу древних традиций.
`}/>
<MyAccordion eventKey={'2'} title={'Винный завод и дегустация вин'} photo={gini} text={`
    После посещения пещеры нас ждет нечто особенное – тур по одному из знаменитых винодельческих заводов в Арени. Это будет удивительное путешествие в мир армянского виноделия, где вы ознакомитесь с процессом производства вина от сбора винограда до финального продукта. В завершение тура вас ждет дегустация лучших образцов вин, произведенных на заводе. Вы сможете насладиться тонкими ароматами и изысканными вкусами местных вин, от чувственного арени до сложных купажей, и узнать больше о культурных традициях виноделия региона.
`}/>
<MyAccordion eventKey={'3'} title={'Нораванк'} photo={noravanq} text={`
    Завершим наш день посещением великолепного монастыря Нораванк, расположенного в узком ущелье реки Амагу. Построенный в XIII-XIV веках, этот монастырь славится своей архитектурой и уникальным расположением. Нораванк – настоящий шедевр средневекового армянского зодчества, окруженный красными скалами, что делает его еще более величественным. Особое внимание стоит обратить на удивительные рельефы и кресты, украшающие стены монастыря. Внутренний двор храмового комплекса наполнен атмосферой умиротворения и духовной силы.
`}/>
</Accordion>
</div>
          : index == 6 ? <div className="container mt-10">
          <Carousel className='excursion_carousel'>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={noravanq}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={tatev}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={chopanuxi}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={xndzoresk}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={shakei_jrvej}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
<h5 className='mt-5'>
Монастырь Нораванк, Монастырь Татев, Канатная дорога “Крылья Татева”, Село Хндзореск, Водопад Шаки
</h5>
<div className="mt-5">
Этот маршрут по югу Армении предлагает уникальное сочетание исторических, культурных и природных достопримечательностей. От древних монастырей и захватывающих дух видов с канатной дороги до уютных пещерных сел и живописных водопадов — каждое место на этом пути подарит вам незабываемые впечатления и глубокое погружение в богатую культуру и природу Армении.
</div>
<Accordion defaultActiveKey="0" className='mb-5'>
<MyAccordion eventKey={'0'} title={'Монастырь Нораванк'} photo={noravanq} text={`
    Монастырь Нораванк, основанный в XIII веке, расположен в живописном ущелье реки Арпа, окруженном красными скалами. Этот великолепный монастырский комплекс славится своей удивительной архитектурой и богатой историей. Центральное место здесь занимает церковь Сурб Аствацацин (Святой Богородицы), украшенная изящными резными украшениями. Монастырь также известен своим местом погребения армянских князей Орбелянов и изысканными фресками. Прогулка по территории Нораванка оставит вас в восхищении от величия армянской архитектуры и природы.
`}/>
<MyAccordion eventKey={'1'} title={'Монастырь Татев'} photo={tatev} text={`
    Монастырь Татев, основанный в IX веке, расположен на высоком утесе над живописной долиной реки Воротан. Этот выдающийся комплекс был не только религиозным центром, но и важным образовательным и культурным центром средневековой Армении. Центральное сооружение комплекса — церковь Святых Павла и Петра, украшенная богатыми фресками и резьбой. Отсюда открываются захватывающие виды на окружающие горы и долины, что делает Татев незабываемым местом для любителей истории и природы.
`}/>
<MyAccordion eventKey={'2'} title={'Канатная дорога “Крылья Татева”'} photo={chopanuxi} text={`
    Для уникального опыта, отправляйтесь на самую длинную в мире двустороннюю канатную дорогу, “Крылья Татева”. Эта инженерная чудо соединяет деревню Алаидзора и монастырь Татев, преодолевая расстояние 5,7 километра за 12 минут. Во время полета над живописной долиной реки Воротан, вы сможете насладиться головокружительными видами на горы и ущелья, что придаст вашему путешествию особую остроту и незабываемые впечатления.
`}/>
<MyAccordion eventKey={'3'} title={'Село Хндзореск'} photo={xndzoresk} text={`
    Следующей остановкой станет древнее село Хндзореск, знаменитое своими пещерными жилищами, которые использовались до середины XX века. Прогулка по узким тропам и мостам среди этих скальных образований позволяет почувствовать себя в другом времени. Захватывающие виды, свежий воздух и уникальная атмосфера делают Хндзореск идеальным местом для тех, кто ищет приключений и новых открытий.
`}/>
<MyAccordion eventKey={'4'} title={'Водопад Шаки'} photo={shakei_jrvej} text={`
    Заключительным аккордом вашего путешествия станет водопад Шаки, расположенный недалеко от города Сисиан. Этот живописный водопад, высотой около 18 метров, окружен зелеными лесами и скалами, создавая волшебную атмосферу. Звук падающей воды и окружающая природа делают это место идеальным для отдыха и медитации. Водопад Шаки — это природный шедевр, который подарит вам умиротворение и вдохновение.
`}/>
</Accordion>
</div>
          : index == 7 ? <div className="container mt-10">
          <Carousel className='excursion_carousel'>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={zvartnots_tachar}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={hripsime}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={gayane}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={mayr_tachar}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
<h5 className='mt-5'>
Храм Звартноц, Эчмиадзинский кафедральный собор, Церковь Св. Гаяне, Церковь Св. Рипсиме
</h5>
<div className="mt-5">
Этот тур по древним святыням Армении позволит вам прикоснуться к многовековой истории и культуре этой удивительной страны. Вы сможете насладиться величием древней архитектуры, почувствовать духовную атмосферу святых мест и понять, почему Армения считается колыбелью христианства.
</div>
<Accordion defaultActiveKey="0" className='mb-5'>
<MyAccordion eventKey={'0'} title={'Церковь Св. Рипсиме'} photo={hripsime} text={`
    Церковь Св. Рипсиме, расположенная в городе Вагаршапат, является одной из самых значимых раннехристианских построек Армении. Построенная в 618 году, она признана шедевром армянской архитектуры. Строгие и монументальные формы церкви прекрасно сочетаются с её духовной значимостью. Здесь покоятся мощи Святой Рипсиме, одной из первых мучениц, пострадавших за христианскую веру в Армении.
`}/>
<MyAccordion eventKey={'1'} title={'Храм Звартноц'} photo={zvartnots_tachar} text={`
    Завершающим этапом нашего тура станет посещение Храма Звартноц, руины которого находятся неподалеку от Эчмиадзина. Построенный в 7 веке, этот храм был одним из самых величественных сооружений своего времени. Несмотря на разрушение в 10 веке, сохранившиеся части храма и сегодня впечатляют своей архитектурной изысканностью и грандиозными размерами. Храм Звартноц включен в список Всемирного наследия ЮНЕСКО и является символом процветания и духовной мощи древней Армении.
`}/>
<MyAccordion eventKey={'2'} title={'Церковь Св. Гаяне'} photo={gayane} text={`
    Недалеко от церкви Св. Рипсиме находится церковь Св. Гаяне, возведенная в 630 году. Эта церковь также посвящена одной из ранних мучениц христианства в Армении. Церковь Св. Гаяне является примером классического армянского архитектурного стиля, её величественные купола и строгие линии создают атмосферу духовного умиротворения и спокойствия.
`}/>
<MyAccordion eventKey={'3'} title={'Эчмиадзинский кафедральный собор'} photo={mayr_tachar} text={`
    Эчмиадзинский кафедральный собор, основанный в 301-303 годах, является не только духовным центром Армении, но и одним из старейших христианских храмов в мире. Согласно преданию, собор был построен на месте, указанном Григорием Просветителем, которому во сне явился Христос и указал место для строительства храма. Эчмиадзинский собор поражает своим величием и исторической значимостью, являясь местом паломничества для христиан со всего мира.
`}/>
</Accordion>
</div>
          : index == 8 ? <div className="container mt-10">
          <Carousel className='excursion_carousel'>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={saxmosavanq}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={amberd}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={ovanavanq}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={tareri_purak}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
<h5 className='mt-5'>
Крепость Амберд, Монастырь Сагмосаванк, Монастырь Ованаванк, Парк Армянского Алфавита
</h5>
<div className="mt-5">
Путешествие по этим историческим и культурным местам Армении откроет для вас богатую историю, духовное наследие и природную красоту этой удивительной страны. Каждое из этих мест предлагает уникальный взгляд на прошлое и настоящее Армении, позволяя полностью погрузиться в ее культуру и традиции. Крепость Амберд
</div>
<Accordion defaultActiveKey="0" className='mb-5'>
<MyAccordion eventKey={'0'} title={'Крепость Амберд'} photo={amberd} text={`
    Крепость Амберд, расположенная на высоте 2300 метров над уровнем моря на склонах горы Арагац, является одним из самых величественных и загадочных мест Армении. Построенная в X веке, она служила важным оборонительным форпостом в средневековой Армении. Величественные стены и башни крепости, а также захватывающие виды на долину Аракса делают это место идеальным для тех, кто интересуется историей и архитектурой. Прогулка по древним каменным стенам перенесет вас в прошлое, позволяя почувствовать дух времен, когда крепость была в самом расцвете своего могущества.
`}/>
<MyAccordion eventKey={'1'} title={'Монастырь Сагмосаванк'} photo={saxmosavanq} text={`
    Монастырь Сагмосаванк, основанный в XIII веке, расположился на краю живописного ущелья реки Касах. Название монастыря переводится как "Монастырь псалмов", что отражает его историческую роль как центра духовного просвещения и переписки. Архитектурный ансамбль Сагмосаванка впечатляет своим гармоничным сочетанием строгих линий и изысканных орнаментов. Особенно примечательны главная церковь Сурб Сиан и колокольня. В окрестностях монастыря открываются потрясающие виды на окружающие горные ландшафты, что делает это место идеальным для медитации и умиротворяющих прогулок.
`}/>
<MyAccordion eventKey={'2'} title={'Монастырь Ованаванк'} photo={ovanavanq} text={`
    Монастырь Ованаванк, основанный в V веке, является одним из древнейших монастырей Армении. Он расположен недалеко от деревни Оганаван, на высоком утесе, откуда открывается захватывающий вид на долину реки Касах. Главная церковь монастыря, Сурб Ованнес, известна своими уникальными фресками и орнаментами, которые являются свидетельством высокого уровня искусства и ремесел средневековой Армении. Ованаванк всегда был важным религиозным и культурным центром, привлекающим паломников и туристов своей исторической и духовной значимостью.
`}/>
<MyAccordion eventKey={'3'} title={'Парк Армянского Алфавита'} photo={tareri_purak} text={`
    Парк Армянского Алфавита, расположенный у подножия горы Арагац, был создан в 2005 году в честь 1600-летия создания армянского алфавита. Этот уникальный парк представляет собой выставку больших каменных букв армянского алфавита, каждая из которых выполнена в индивидуальном художественном стиле. Парк является данью уважения Месропу Маштоцу, создателю армянской письменности, и символом богатого культурного наследия Армении. Гуляя по парку, вы сможете узнать о значении и истории каждой буквы, а также насладиться видами на живописные горные пейзажи.
`}/>
</Accordion>
</div>
          : index == 9 ? <div className="container mt-10">
          <Carousel className='excursion_carousel'>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={zarni_parni}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={haxpat}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={axtala}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={sanahin}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
<h5 className='mt-5'>
Монастырь Ахпат, Пещерно-крепостной комплекс Зарни-Парни, Крепость Ахтала, Монастырь Санаин
</h5>
<div className="mt-5">
Этот маршрут по историческим и культурным достопримечательностям Армении подарит вам незабываемые впечатления и глубокое понимание богатого наследия этой удивительной страны. Окунитесь в прошлое, наслаждаясь красотой и загадочностью древних монастырей и крепостей, и откройте для себя магию Армении!
</div>
<Accordion defaultActiveKey="0" className='mb-5'>
<MyAccordion eventKey={'0'} title={'Монастырь Ахпат'} photo={haxpat} text={`
    Ваше путешествие начинается с посещения величественного монастыря Ахпат, расположенного в Лорийской области Армении. Этот монастырь, основанный в 10 веке, представляет собой выдающийся пример средневековой армянской архитектуры. Его грандиозные здания, украшенные резьбой по камню, создают атмосферу умиротворения и духовного величия. В монастыре сохранились древние рукописи, ценные артефакты и фрески, рассказывающие историю многовековой христианской культуры Армении.
`}/>
<MyAccordion eventKey={'1'} title={'Пещерно-крепостной комплекс Зарни-Парни'} photo={zarni_parni} text={`
    Следующая остановка — удивительный пещерно-крепостной комплекс Зарни-Парни, который поражает своей таинственной и суровой красотой. Комплекс расположен на склонах горного массива и состоит из нескольких уровней пещер, соединенных между собой туннелями и лестницами. Эти древние укрытия использовались в качестве оборонительных сооружений и монастырских келий. Прогуливаясь по запутанным коридорам и скрытым залам, вы ощутите дух времени и прикоснетесь к тайнам древних обитателей этого места.
`}/>
<MyAccordion eventKey={'2'} title={'Крепость Ахтала'} photo={axtala} text={`
    Далее маршрут приводит вас к крепости Ахтала, одной из самых хорошо сохранившихся средневековых крепостей Армении. Возведенная в 10-13 веках, эта крепость служила стратегическим оборонительным пунктом и центром религиозной жизни. Внутри крепости находится великолепный монастырь Ахтала с его уникальными фресками, которые поражают своей яркостью и детализацией. Прогулка по стенам крепости и восхождение на её башни открывают потрясающие виды на окружающие горы и долины.
`}/>
<MyAccordion eventKey={'3'} title={'Монастырь Санаин'} photo={sanahin} text={`
    Заключительная часть маршрута приведет вас к монастырю Санаин, расположенному на живописном склоне горы, в окружении зеленых лесов и рек. Этот монастырский комплекс, также основанный в 10 веке, считается одним из важнейших культурных и образовательных центров средневековой Армении. В Санаине вы сможете увидеть древние хачкары, рукописи и архитектурные памятники, отражающие богатую историю армянской церкви и народа. Атмосфера покоя и величия этого места оставит незабываемое впечатление и позволит вам проникнуться духом древней Армении.
`}/>
</Accordion>
</div>
          : index == 10 ? <div className="container mt-10">
          <Carousel className='excursion_carousel'>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={gyumri}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={gyumri2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={gyumri3}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
<h5 className='mt-5'>
Гюмри – Сердце Армянской Культуры
</h5>
<div className="my-5">
Путешествие в Гюмри и посещение Черной крепости обещает незабываемые впечатления и возможность углубиться в богатую историю и культуру Армении, оставляя яркие воспоминания на долгие годы.
<br /><br />
Гюмри, второй по величине город Армении, расположен в живописной долине реки Ахурян. Город известен своим богатым культурным наследием, а также удивительной архитектурой, сочетающей элементы различных эпох и стилей. История Гюмри насчитывает более двух тысяч лет, и каждый уголок города пропитан духом времени.
<br /><br />
Главные достопримечательности Гюмри включают:
<hr />
<ul>
  <li>Площадь Вартанац: Центральная площадь города, окруженная красивыми зданиями в стиле неоклассицизма и модерна.</li>
  <li>Музей Нардос: Этот музей представляет собой удивительную коллекцию армянского искусства, от древних артефактов до произведений современных художников.</li>
  <li>Церковь Святого Ншан: Одно из старейших религиозных сооружений города, построенное в 19 веке. Здесь вы сможете почувствовать дух древней Армении и насладиться великолепной архитектурой.</li>
  <li>Памятник землетрясения 1988 года: Трогательный мемориал, посвященный жертвам разрушительного землетрясения, которое унесло тысячи жизней и навсегда изменило облик города.</li>
</ul>
<br /><br />
Черная Крепость – Свидетель Великой Истории
<br /><br />
Расположенная на возвышенности в непосредственной близости от Гюмри, Черная крепость (Сев Берд) является одним из наиболее впечатляющих исторических памятников Армении. Построенная в 1830 году, крепость сыграла ключевую роль в русско-турецких войнах и служила важным оборонительным сооружением.
<br /><br />
При посещении Черной крепости вы сможете:
<ul>
  <li>Увидеть мощные стены: Крепость окружена массивными стенами, которые создают ощущение неприступности и величия.</li>
  <li>Прогуляться по внутренним дворам: Исследуйте внутренние дворы и помещения, где можно почувствовать атмосферу прошлого.</li>
  <li>Насладиться панорамным видом: С вершины крепости открывается потрясающий вид на Гюмри и окрестные пейзажи, который не оставит равнодушным ни одного путешественника.</li>
</ul>
<br /><br />
Почему Стоит Посетить Гюмри и Черную Крепость
<br /><br />
Путешествие в Гюмри и посещение Черной крепости – это уникальная возможность погрузиться в богатую историю и культуру Армении. Эти места предлагают удивительные виды, глубокое историческое значение и неповторимую атмосферу, которая останется в вашей памяти на долгие годы. Позвольте нам стать вашими гидами в этом незабываемом путешествии и открыть для вас все тайны и красоты этого удивительного региона.
</div>
</div>
          : index == 11 ? <div className="container mt-10">
          <Carousel className='excursion_carousel'>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={konyak1}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={konyak2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={konyak3}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
<h5 className='mt-5'>
Обзорный тур Еревана дегустация конечно завода Арарат
</h5>
<div className="my-5">
Ереван как столицу Армении, известную своей древней историей, архитектурой из розового туфа и богатым культурным наследием. Он подчеркивает туристическую привлекательность города благодаря многочисленным достопримечательностям, ночной жизни и кулинарным традициям. Также рассказывается о Коньячном Заводе АРАРАТ, который предлагает экскурсии по производству коньяка, позволяя гостям погрузиться в историю и насладиться уникальным вкусом этого напитка. Город Ереван:
<br /><br />
Столица Армении – Ереван, город, который на 29 лет старше Рима! Датируется 782-ым годом до н.э., основан на месте древней крепости Эребуни, построенной царем Аргишти Первым. Ереван часто называют «розовым городом», ввиду того что многие здания здесь построены из розового туфа. Столица является одним из известных туристических направлений благодаря многочисленным достопримечательностям, активной ночной жизни, прекрасным музеям и чудесным паркам. Пешая прогулка по Еревану – лучший способ по-настоящему погрузиться в культуру, историю и местную кухню. Площадь Республики с музыкальными фонтанами – это одна из поистине великолепных достопримечательностей Еревана: с начала мая до середины осени здесь проводится фантастическое шоу поющих и танцующих фонтанов в сопровождении прекрасной музыки разных жанров. Не пропустите еще одну ключевую достопримечательность города – Комплекс Каскад, с высоты которого открываются захватывающие виды на Ереван, которыми сможете в полной мере насладиться ночью, когда город красиво освещен.
<br /><br />
Коньячный Завод АРАРАТ:
<hr />
Посетите Коньячный Завод АРАРАТ и погрузитесь в историю производства коньяка в Армении. Завод расположен в самом сердце Еревана и предлагает увлекательные экскурсии, в ходе которых вы узнаете о процессе изготовления этого благородного напитка, сможете посетить производственные помещения и, конечно же, отведать различные сорта коньяка. Это уникальная возможность окунуться в атмосферу армянской культуры и насладиться изысканным вкусом местного коньяка.
</div>
</div>
          :
            null
          }
        </>
    );
};