import Accordion from 'react-bootstrap/Accordion';

export const MyAccordion = ({eventKey, title, photo, text}) => {
    return (
        <>
            <Accordion.Item className='excursion_item mt-5' eventKey={eventKey}>
                    <Accordion.Header className='excursion_item_header'>{title}</Accordion.Header>
                    <Accordion.Body className='excursion_item_body'>
                      <div className="excursion_img text-center">
                        <img src={photo} alt="photo excursion" />
                      </div>
                      <br />
                      {text}
                    </Accordion.Body>
                  </Accordion.Item>
        </>
    );
};