import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const Scroll = () => {
    const { pathname } = useLocation();
    useEffect(()=>{
        window.scrollTo(0,0);

        if(pathname !== '/book-now'){
            localStorage.setItem('isTour', false);
        };

        const metaTag1 = document.querySelector('meta[property="og:url"]');
        metaTag1.content = 'https://big-voyage.am'+pathname;
        const metaTag2 = document.querySelector('link[rel="canonical"]');
        metaTag2.href = 'https://big-voyage.am'+pathname;
        const metaTag3 = document.querySelector('meta[name="robots"]');
        if(metaTag3.content !== 'index, follow'){
            metaTag3.content = 'index, follow';
        };
    }, [pathname]);
};