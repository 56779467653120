import Card from 'react-bootstrap/Card';
import { Lang } from './Lang';
import { Link } from 'react-router-dom';
import '../assets/css/MyCard/myCard.css';

export const MyCard = ({photo, title, text, link}) => {
  return (
    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-10 col-sm-12 col-12 offset-xxl-0 offset-xl-0 offset-lg-0 offset-md-1 offset-sm-0 offset-0 mt-5">
        <Card>
      <Card.Img variant="top" src={photo} alt={title} />
      <Card.Body>
        <Card.Title><Lang text={title} /></Card.Title>
        <Card.Text><Lang text={text} /></Card.Text>
        <Link to={'/' + link} className='link_a'><Lang text='read_more' /></Link>
      </Card.Body>
    </Card>
    </div>
  );
};