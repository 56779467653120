import React, { useState } from 'react';

export const YearPicker = ({ selectedYear, onClick }) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(100), (val, index) => currentYear - 50 + index);
  const [menu, setMenu] = useState(false);

  return (
    <>
      <select onClick={() => setMenu(true)}>
        <option hidden>{selectedYear}</option>
      </select>

      {
        menu ? (
          <div className="date_menu">
            {years.map((year) => (
              <div className={selectedYear === year ? 'selected_year' : null} key={year} onClick={()=>{onClick(year); setMenu(false)}}>
                {year}
              </div>
      ))}
          </div>
        ) : null
      }
    </>
  );
};