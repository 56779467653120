import { useState } from 'react';
import excursion1 from '../assets/images/excursion1.jpg';
import excursion2 from '../assets/images/excursion2.jpg';
import excursion3 from '../assets/images/excursion3.jpg';
import excursion4 from '../assets/images/excursion4.jpg';
import excursion5 from '../assets/images/excursion5.jpg';
import excursion6 from '../assets/images/excursion6.jpg';
import excursion7 from '../assets/images/excursion7.jpg';
import excursion8 from '../assets/images/excursion8.jpg';
import excursion9 from '../assets/images/excursion9.jpg';
import excursion10 from '../assets/images/excursion10.jpg';
import excursion11 from '../assets/images/excursion11.jpg';
import excursion12 from '../assets/images/excursion12.jpg';
import excursion13 from '../assets/images/excursion13.jpg';
import excursion14 from '../assets/images/excursion14.jpg';
import excursion15 from '../assets/images/excursion15.jpg';
import excursion16 from '../assets/images/excursion16.jpg';
import kamar1 from '../assets/images/kamar1.jpg';
import garni_tachar from '../assets/images/garni_tachar.jpg';
import gexard_ekexeci from '../assets/images/gexard_ekexeci.jpg';
import qareri_simfonya1 from '../assets/images/qareri_simfonya1.jpg';
import kamar2 from '../assets/images/kamar2.webp';
import garni_tachar2 from '../assets/images/garni_tachar2.jpg';
import gexard_ekexeci2 from '../assets/images/gexard_ekexeci2.jpg';
import qareri_simfonya2 from '../assets/images/qareri_simfonya2.jpg';
import sevan from '../assets/images/sevan.webp';
import dilijan from '../assets/images/dilijan.jpeg';
import haxarcin from '../assets/images/haxarcin.jpg';
import goshavanq from '../assets/images/goshavanq.jpg';
import xor_virap from '../assets/images/xor_virap.jpeg';
import peshera from '../assets/images/peshera.jpg';
import gini from '../assets/images/gini.jpg';
import noravanq from '../assets/images/noravanq.jpg';
import tatev from '../assets/images/tatev.jpg';
import chopanuxi from '../assets/images/chopanuxi.jpeg';
import xndzoresk from '../assets/images/xndzoresk.jpg';
import shakei_jrvej from '../assets/images/shaki_jrvej.jpeg';
import zvartnots_tachar from '../assets/images/zvartnots_tachar.jpg';
import hripsime from '../assets/images/hripsime.jpg';
import gayane from '../assets/images/gayane.jpg';
import mayr_tachar from '../assets/images/mayr_tachar.jpg';
import amberd from '../assets/images/amberd.jpg';
import saxmosavanq from '../assets/images/saxmosavanq.jpeg';
import ovanavanq from '../assets/images/ovanavanq.jpeg';
import tareri_purak from '../assets/images/tareri_purak.webp';
import haxpat from '../assets/images/haxpat.jpg';
import zarni_parni from '../assets/images/zarni_parni.jpg';
import axtala from '../assets/images/axtala.jpg';
import sanahin from '../assets/images/sanahin.jpg';
import gyumri from '../assets/images/gyumri.webp';
import gyumri2 from '../assets/images/gyumri2.jpg';
import gyumri3 from '../assets/images/gyumri3.jpg';
import konyak1 from '../assets/images/konyak1.jpg';
import konyak2 from '../assets/images/konyak2.jpg';
import konyak3 from '../assets/images/konyak3.jpg';
import airoport_zvartnots from '../assets/images/airoport_zvartnots.webp';
import caxkadzor from '../assets/images/caxkadzor.webp';
import tbilisi from '../assets/images/tbilisi.webp';
import tbilisi2 from '../assets/images/tbilisi2.webp';
import jermuk from '../assets/images/jermuk.webp';
import vanadzor from '../assets/images/vanadzor.webp';
import '../assets/css/Gallery/gallery.css';

export const Gallery = () => {
    const [photoBig, setPhotoBig] = useState(false);
    const [initialPhoto, setInitialPhoto] = useState(null);
    const galleryArr = [
        excursion1, excursion2, excursion3, excursion4, excursion5, excursion6, excursion7, excursion8, excursion9, excursion10, excursion11, excursion12, excursion13, excursion14, excursion15, excursion16, kamar1, garni_tachar, gexard_ekexeci, qareri_simfonya1, kamar2, garni_tachar2, gexard_ekexeci2, qareri_simfonya2, sevan, dilijan, haxarcin, goshavanq, xor_virap, peshera, gini, noravanq, tatev, chopanuxi, xndzoresk, shakei_jrvej, zvartnots_tachar, hripsime, gayane, mayr_tachar, amberd, saxmosavanq, ovanavanq, tareri_purak, haxpat, zarni_parni, axtala, sanahin, gyumri, gyumri2, gyumri3, konyak1, konyak2, konyak3, airoport_zvartnots, caxkadzor, tbilisi, tbilisi2, jermuk, vanadzor
    ];
    return (
        <>
            <div className="container-fluid mt-10">
                <div className="row">
                        {
                            galleryArr.map((photo, key)=>{
                                return (
                                    <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-5" onClick={()=>{
                                        setInitialPhoto(key);
                                        setPhotoBig(true);
                                    }} key={key}>
                                        <img src={photo} className="gallery_photo img-fluid" alt="Big Voyage Туры Ереван - Тбилиси, Yerevan - Tbilisi, Երեւան - Թբիլիսի" />
                                    </div>
                                )
                            })
                        }
                </div>
            </div>

            {
                photoBig ? <div className="photo_open" onClick={(e)=>{
                    if(e.target.getAttribute('class') === 'photo_open'){
                        setPhotoBig(false);
                    }
                }}>
                <div className="close_photo" onClick={()=>{
                    setPhotoBig(false);
                }}>X</div>
                <div className="photo_prev" onClick={()=>{
                    if(initialPhoto !== 0){
                        setInitialPhoto(initialPhoto-1);
                    }
                }}>&lt;</div>
                <img src={galleryArr[initialPhoto]} alt="gallery photo" />
                <div className="photo_next" onClick={()=>{
                    if(initialPhoto !== galleryArr.length-1){
                        setInitialPhoto(initialPhoto+1);
                    }
                }}>&gt;</div>
            </div> : null
            }
        </>
    );
};