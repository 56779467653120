import { useState, useEffect } from 'react';
import axios from 'axios';
import { Lang } from './Lang';

export const Exchanger = () => {
    const [currencies, setCurrencies] = useState([]);
        const [fromCurrency, setFromCurrency] = useState('AMD');
        const [toCurrency, setToCurrency] = useState('USD');
        const [amount, setAmount] = useState(1);
        const [convertedAmount, setConvertedAmount] = useState(null);
        const api_key = 'a3d1bd25aeefd624de4cef98';
      
        useEffect(() => {
          const fetchCurrencies = async () => {
            try {
              const response = await axios.get(`https://v6.exchangerate-api.com/v6/${api_key}/latest/USD`);
              setCurrencies(Object.keys(response.data.conversion_rates));
            } catch (error) {
              console.error('Error fetching currencies:', error);
            }
          };
      
          fetchCurrencies();
        }, []);
      
        const handleConvert = async () => {
          try {
            const response = await axios.get(`https://v6.exchangerate-api.com/v6/${api_key}/pair/${fromCurrency}/${toCurrency}/${amount}`);
            setConvertedAmount(response.data.conversion_result);
          } catch (error) {
            console.error('Error converting currency:', error);
          }
        };

    return (
        <>
            <div id='exchanger' className='mt-5'>
      <h2 className='mb-4'><Lang text={'exchange_title'} /></h2>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <br /><br />
        <select value={fromCurrency} onChange={(e) => setFromCurrency(e.target.value)}>
          {currencies.map((currency) => (
            <option key={currency} value={currency}>
              {currency}
            </option>
          ))}
        </select>
        <br />
        <span> <Lang text={'to'} /> </span>
        <br />
        <select value={toCurrency} onChange={(e) => setToCurrency(e.target.value)}>
          {currencies.map((currency) => (
            <option key={currency} value={currency}>
              {currency}
            </option>
          ))}
        </select>
        <br />
        <button className='mt-3' onClick={handleConvert}><Lang text={'convert'} /></button>
      {convertedAmount !== null && (
        <div className='mt-3'>
          <h3><Lang text={'converted_amount'} />: {convertedAmount}</h3>
        </div>
      )}
    </div>
        </>
    );
};