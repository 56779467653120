import { useState } from "react";

export const HourPicker = ({ selectedHour, onClick }) => {
  const hours = Array.from(new Array(24), (val, index) => index);
  const [menu, setMenu] = useState(false);

  return (
    <>
      <select onClick={() => setMenu(true)}>
        <option hidden>{selectedHour}</option>
      </select>

      {
        menu ? (
          <div className="date_menu hour">
            {hours.map((hour) => (
              <div className={selectedHour === hour ? 'selected_year' : null} key={hour} onClick={()=>{onClick(hour); setMenu(false)}}>
                {hour}
              </div>
            ))}
          </div>
        ) : null
      }
    </>
  );
};