import { Link } from 'react-router-dom';

export const ExcursionItem = ({index, photo, title, text}) => {
    return (
        <>
            <Link to='/excursion-details' onClick={()=>{
              localStorage.setItem('excursion_details_page', index);
              localStorage.setItem('tourTitle', title.trim());
              }}>
                <div className="excursion_home_item mt-5">
                  <div className="excursion_img text-center">
                  <img src={photo} alt="photo excursion" />
                  </div>
                  <div className="excursion_home_text">
                    <h5>
                      {title}
                    </h5>
                    {text}
                  </div>
                </div>
                </Link>
        </>
    );
};