import '../assets/css/Collaboration/collaboration.css';

export const Collaboration = () => {
    return (
        <>
            <div className="container mt-10">
              <h2 className='seo_text'>Big Voyage открыт для партнерства и сотрудничества с другими компаниями и организациями в сфере туризма. Мы готовы рассматривать различные формы партнерства и сотрудничества, включая:</h2>
              <div className="mt-5">
                <ul>
                    <li>Туристические агентства: Разработка совместных туров и предложений для наших клиентов.</li>
                    <li>Отели и гостиницы: Установление партнерских отношений и скидок для наших гостей.</li>
                    <li>Транспортные компании: Совместные программы для пассажирских перевозок и трансферов.</li>
                </ul>
                Если вы заинтересованы в партнерстве с нами или у вас есть предложение о сотрудничестве, пожалуйста, свяжитесь с нами для дальнейшего обсуждения.
              </div>
            </div>
        </>
    );
};