import { Link } from 'react-router-dom';
import { Lang } from '../components/Lang';
import { MyCard } from '../components/MyCard';
import home_book_now from '../assets/images/home_book_now.webp';
import Accordion from 'react-bootstrap/Accordion';
import photoContactUs from '../assets/images/contact_us.jpg';
import photoReviews from '../assets/images/reviews.jpg';
import photoCollaboration from '../assets/images/collaboration.jpg';
import home_excursion from '../assets/images/home_excursion.jpg';
import home_transfers from '../assets/images/home_transfers.jpg';
import why_chose_us from '../assets/images/why_chose_us.jpg';
import '../assets/css/Home/home.css';

export const Home = () => {
    return (
        <>
              <div className='mt-6' id="home_book_now">
                <img fetchPriority='high' src={home_book_now} alt="Трансфер из Еревана в Тбилиси, Yerevan - Tbilisi, Երեւան - Թբիլիսի" />
                <Link to='/book-now' />
              </div>

            <div className="container mt-6">
              <h1 id='site_big_title'><Lang text='big_voyage_your_partner' /></h1>
              <hr />
              <h2 className='seo_text'>Добро пожаловать на сайт Big Voyage! Мы рады приветствовать вас и предложить наши уникальные услуги по организации путешествий в удивительные места Армении.</h2>
            </div>

            <Accordion className='home_accordion' defaultActiveKey="0">
            <div className="container mt-10 mb-5">
            <Link to='/excursions'>
            <Accordion.Item>
              <div className='home_accordion_image_text'>
                <img src={home_excursion} alt="photo" />
                <Accordion.Header><Lang text='excursions' /></Accordion.Header>
              </div>
      </Accordion.Item>
            </Link>
            </div>

            <div className="container mt-10 mb-5">
            <Link to='/individual-transfers'>
            <Accordion.Item>
            <div className='home_accordion_image_text'>
                <img src={home_transfers} alt="photo" />
                <Accordion.Header><Lang text='individual_transportation' /></Accordion.Header>
              </div>
      </Accordion.Item>
            </Link>
            </div>

            <div className="container mt-10 mb-5">
            <Accordion.Item eventKey="2" onClick={()=>{
              const text = document.getElementById('why_chose_us_text');
              if(text){
                text.click();
              };
            }}>
            <div className='home_accordion_image_text'>
                <img src={why_chose_us} alt="photo" />
                <Accordion.Header><Lang text='why_chose_us' /><span id='why_chose_us_text'></span></Accordion.Header>
              </div>
        <Accordion.Body>
        <ul>
                <li><Lang text='why_chose_us1' /></li>
                <li><Lang text='why_chose_us2' /></li>
                <li><Lang text='why_chose_us3' /></li>
                <li><Lang text='why_chose_us4' /></li>
              </ul>
                <Lang text='start_your_journey' />
                <br /><br />
                <Link to='/book-now' className='link_a'><Lang text='book_now' /></Link>
        </Accordion.Body>
      </Accordion.Item>
            </div>
            </Accordion>

            <div className="container mb-6">
              <div className="row">
                <MyCard photo={photoContactUs} title={'contact_us'} text={'card_contact_us_text'} link={'contact-us'} />
                <MyCard photo={photoReviews} title={'customer_reviews'} text={'card_customer_reviews_text'} link={'reviews'} />
                <MyCard photo={photoCollaboration} title={'partnership_and_collaboration'} text={'card_partnership_and_collaboration_text'} link={'collaboration'} />
              </div>
            </div>
        </>
    )
};