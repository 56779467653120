import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FirstItems } from "./components/FirstItems";
import { Header } from "./components/Header";
import { Scroll } from "./components/Scroll";
import { Home } from "./pages/Home";
import { Excursions } from "./pages/Excursions";
import { ExcursionDetails } from "./pages/ExcursionDetails";
import { IndividualTransportation } from "./pages/IndividualTransportation";
import { AboutUs } from "./pages/AboutUs";
import { ContactUs } from "./pages/ContactUs";
import { Reviews } from "./pages/Reviews";
import { Collaboration } from "./pages/Collaboration";
import { BookNow } from "./pages/BookNow";
import { AddReview } from "./pages/AddReview";
import { Gallery } from "./pages/Gallery";
import { Footer } from "./components/Footer";
import { Error } from "./pages/Error";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/main.css';

export default function App() {
  const [site, setSite] = useState(false);

  return (
    <>
      <BrowserRouter>
      <FirstItems/>
        <Header site={site} setSite={setSite} />
        <Scroll />
        <div className="content">
        <Routes>
          <Route index element={<Home />} />
          <Route path="/excursions" element={<Excursions />} />
          <Route path="/excursion-details" element={<ExcursionDetails />} />
          <Route path="/individual-transfers" element={<IndividualTransportation />} />
          <Route path="/collaboration" element={<Collaboration />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/book-now" element={<BookNow />} />
          <Route path="/add-review" element={<AddReview />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="*" element={<Error />} />
        </Routes>
        </div>
        <Footer />
    </BrowserRouter>
    </>
  );
}