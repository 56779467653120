import { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useLocation } from 'react-router-dom';
import { Lang } from './Lang';
import logo from '../assets/images/logo.webp';
import en from '../assets/images/en.webp';
import ru from '../assets/images/ru.webp';
import am from '../assets/images/am.webp';
import '../assets/css/Header/header.css';

export const Header = ({ site, setSite}) => {
    const curLang = localStorage.getItem('lang');
    const media = window.matchMedia("(max-width: 1199px)");
    const [isHome, setIsHome] = useState(false);
    const [isBookNow, setIsBookNow] = useState(false);
    const {pathname} = useLocation();

    useEffect(()=>{
      if(pathname === '/'){
        setIsHome(true);
      }else if(pathname === '/book-now'){
        setIsBookNow(true);
      };
      if(isHome){
        setIsHome(false);
      }else if(isBookNow){
        setIsBookNow(false);
      };
    }, [pathname]);

    return (
        <>
            <Navbar expand='xl' id="header">
      <div className='container-fluid'>
        <Navbar.Brand><Link to="/" onClick={()=>{
          if(pathname === '/'){
              document.getElementById('home_book_now').scrollIntoView();
          }
        }}><img src={logo} alt="logo" className='logo' /></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" id='nav_toogler' />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={curLang !== 'am' ? 'me-auto nav_links' : 'me-auto nav_links header_armenian_font'}>
            <Link to="/" onClick={()=>{
              if(media.matches){
            document.getElementById('nav_toogler').click();
        }
            }}><Lang text='home' /></Link>
            <Link to="/excursions" onClick={()=>{
              if(media.matches){
            document.getElementById('nav_toogler').click();
        }
            }}><Lang text='excursions' /></Link>
            <Link to="/individual-transfers" onClick={()=>{
              if(media.matches){
            document.getElementById('nav_toogler').click();
        }
            }}><Lang text='individual_transportation' /></Link>
            <Link to="/collaboration" onClick={()=>{
              if(media.matches){
            document.getElementById('nav_toogler').click();
        }
            }}><Lang text='partnership_and_collaboration' /></Link>
            <Link to="/about-us" onClick={()=>{
              if(media.matches){
            document.getElementById('nav_toogler').click();
        }
            }}><Lang text='about_us' /></Link>
            <Link to="/contact-us" onClick={()=>{
              if(media.matches){
            document.getElementById('nav_toogler').click();
        }
            }}><Lang text='contact_us' /></Link>
            <Link to="/reviews" onClick={()=>{
              if(media.matches){
            document.getElementById('nav_toogler').click();
        }
            }}><Lang text='customer_reviews' /></Link>
            <Link to="/book-now" onClick={()=>{
              if(media.matches){
            document.getElementById('nav_toogler').click();
        }
              if(pathname === '/excursion-details'){
                localStorage.setItem('isTour', true);
              }
            }}><Lang text='book_now' /></Link>
          </Nav>
          <NavDropdown title={curLang === 'en' ? <><img src={en} alt="en" /> English</> : 
          curLang === 'ru' ? <><img src={ru} alt="ru" /> Русский</> : 
          <><img src={am} alt="am" /> Հայերեն</>} id="basic-nav-dropdown">
              <NavDropdown.Item value="en" onClick={()=>{
                localStorage.setItem('lang', 'en');
                setSite(!site);
              }}><img src={en} alt="en" /> English</NavDropdown.Item>
              <NavDropdown.Item value="ru" onClick={()=>{
                localStorage.setItem('lang', 'ru');
                setSite(!site);
              }}><img src={ru} alt="ru" /> Русский</NavDropdown.Item>
              <NavDropdown.Item value="am" onClick={()=>{
                localStorage.setItem('lang', 'am');
                setSite(!site);
              }}><img src={am} alt="am" /> Հայերեն</NavDropdown.Item>
            </NavDropdown>
        </Navbar.Collapse>
      </div>
    </Navbar>
    {
      !isHome && !isBookNow ? <div id="to_order">
        <Link to='/book-now' onClick={()=>{
      if(pathname === '/excursion-details'){
        localStorage.setItem('isTour', true);
      }
    }}>Заказать</Link>
      </div>
    : null
    }
        </>
    )
};