import { Lang } from '../components/Lang';
import Accordion from 'react-bootstrap/Accordion';
import { TransferTable } from '../components/TransferTable';
import { Exchanger } from '../components/Exchanger';
import airoport_zvartnots from '../assets/images/airoport_zvartnots.webp';
import caxkadzor from '../assets/images/caxkadzor.webp';
import sevan from '../assets/images/sevan.webp';
import gyumri from '../assets/images/gyumri.webp';
import tbilisi from '../assets/images/tbilisi.webp';
import tbilisi2 from '../assets/images/tbilisi2.webp';
import jermuk from '../assets/images/jermuk.webp';
import vanadzor from '../assets/images/vanadzor.webp';
import '../assets/css/IndividualTransportation/individualTransportation.css';

export const IndividualTransportation = () => {
    return (
        <>
            <div className="container mt-10">
                <h1 className='text-center mb-5 pt-5 page_title_cur' onClick={()=>{
                    document.getElementById('yerevan_tbilisi').scrollIntoView({behavior: 'smooth'});
                    document.querySelector('#yerevan_tbilisi button').click();
                }}><Lang text='yerevan_tbilisi'/></h1>
                Big Voyage предлагает широкий спектр индивидуальных трансферов для вашего комфортного перемещения по Армении и Грузии. Независимо от того, нужно ли вам трансфер из аэропорта в гостиницу, поездка в исторические достопримечательности или перемещение между городами, мы готовы предоставить вам высококачественный сервис и удобные условия:
                <div className='transport_service_prices mt-5'><Lang text={'transport_service_prices'} /></div>
                <Accordion defaultActiveKey="0">
                <TransferTable eventKey='0' photo={airoport_zvartnots} title='table_title1' price1='7' price2='10' price3='14' price4='20' />
                <TransferTable eventKey='1' photo={caxkadzor} title='table_title2' price1='20' price2='23' price3='27' price4='45' />
                <TransferTable eventKey='2' photo={sevan} title='table_title3' price1='22' price2='25' price3='29' price4='50' />
                <TransferTable eventKey='3' photo={gyumri} title='table_title4' price1='35' price2='38' price3='42' price4='68' />
                <TransferTable eventKey='4' photo={tbilisi} title='table_title5' price1='50' price2='56' price3='60' price4='138' />
                <TransferTable eventKey='5' photo={tbilisi2} title='table_title6' price1='50' price2='56' price3='60' price4='138' isHot={'hot_offer'} />
                <TransferTable eventKey='6' photo={jermuk} title='table_title7' price1='40' price2='43' price3='47' price4='90' />
                <Accordion.Item className='individual_item mt-5' eventKey='7'>
              <div className='individual_photo_title'>
                <img src={vanadzor} alt="photo excursion" />
              </div>
                <Accordion.Header><Lang text={'table_title8'} /></Accordion.Header>
              <Accordion.Body className='p-0'>
              <div className="transfer_table mt-5">
                    <div className='transfer_title'><Lang text={'table_title8'} /></div>
                    <table>
                        <tr>
                            <th><Lang text={'type'} /></th>
                            <th><Lang text={'passengers'} /></th>
                            <th><Lang text={'baggage'} /></th>
                            <th><Lang text={'price'} /></th>
                        </tr>
                        <tr>
                            <td><Lang text={'simple_sedan'} /></td>
                            <td>3</td>
                            <td>3</td>
                            <td>35 000 <Lang text={'amd'} /></td>
                        </tr>
                        <tr>
                            <td><Lang text={'comfort'} /></td>
                            <td>4</td>
                            <td>3</td>
                            <td>38 000 <Lang text={'amd'} /></td>
                        </tr>
                        <tr>
                            <td><Lang text={'minivan'} /></td>
                            <td>7</td>
                            <td>7</td>
                            <td>42 000 <Lang text={'amd'} /></td>
                        </tr>
                    </table>
                </div>
              </Accordion.Body>
            </Accordion.Item>
                </Accordion>

                <Exchanger />

                <div className="mt-5">
                    <h2>Удобство и комфорт</h2>
                    <hr />
                    <ul>
                        <li>Наши профессиональные водители и комфортные транспортные средства обеспечат вам приятное и безопасное путешествие на любом направлении.</li>
                    </ul>
                </div>
                <div className="mt-5">
                    <h2>Гибкость и индивидуальный подход</h2>
                    <hr />
                    <ul>
                        <li>Мы готовы предложить вам индивидуальные маршруты и трансферы, адаптированные под ваши предпочтения и расписание.</li>
                    </ul>
                </div>
                <div className="mt-5">
                    <h2>Широкий выбор направлений</h2>
                    <hr />
                    <ul>
                        <li>Наши трансферы доступны по всей территории Армении и Грузии, включая аэропорты, города, курорты и другие туристические объекты.</li>
                    </ul>
                </div>
                <div className="my-5">
                  Забронируйте ваш индивидуальный трансфер прямо сейчас и наслаждайтесь комфортом и удобством с Big Voyage!
                </div>
            </div>
        </>
    );
};