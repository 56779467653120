import { useEffect } from 'react';
import { Lang } from '../components/Lang';
import '../assets/css/Error/error.css';

export const Error = () => {

    useEffect(()=>{
        document.title = 'Страница не найдена';
        const metaTag = document.querySelector('meta[property="og:title"]');
        metaTag.content = 'Страница не найдена';
        const metaTag2 = document.querySelector('meta[name="robots"]');
        metaTag2.content = 'noindex, follow';
      }, []);

    return (
        <>
            <div className="error">
                404
                <div><Lang text='this_page_was_not_found' /></div>
            </div>
        </>
    );
};