import Accordion from 'react-bootstrap/Accordion';
import { Lang } from "./Lang";
import airoport_zvartnots from '../assets/images/airoport_zvartnots.webp';

export const TransferTable = ({eventKey, photo, title, price1, price2, price3, price4, isHot}) => {
    return (
        <>
            <Accordion.Item className='individual_item mt-5' id={isHot ? 'yerevan_tbilisi' : null} title={Lang({text: title})} eventKey={eventKey}>
              <div className='individual_photo_title'>
                <img fetchPriority={photo === airoport_zvartnots ? 'high' : null} src={photo} alt={Lang({text: title})} />
              </div>
                <Accordion.Header><Lang text={title} /></Accordion.Header>
              <Accordion.Body className='p-0'>
              <div className={isHot ? `transfer_table mt-5 ${isHot}` : "transfer_table mt-5"}>
                {isHot ? <>
                    <div className="hot_text"><Lang text={'hot_offer'} /></div>
                    <div className="transfer_title"><Lang text={title} /></div>
                </>
                :
                <div className="transfer_title"><Lang text={title} /></div>    
            }
                <table>
                    <tr>
                        <th><Lang text={'type'} /></th>
                        <th><Lang text={'passengers'} /></th>
                        <th><Lang text={'baggage'} /></th>
                        <th><Lang text={'price'} /></th>
                    </tr>
                    <tr>
                        <td><Lang text={'simple_sedan'} /></td>
                        <td>3</td>
                        <td>3</td>
                        <td>{price1} 000 <Lang text={'amd'} /></td>
                    </tr>
                    <tr>
                        <td><Lang text={'comfort'} /></td>
                        <td>4</td>
                        <td>3</td>
                        <td>{price2} 000 <Lang text={'amd'} /></td>
                    </tr>
                    <tr>
                        <td><Lang text={'minivan'} /></td>
                        <td>7</td>
                        <td>7</td>
                        <td>{price3} 000 <Lang text={'amd'} /></td>
                    </tr>
                    <tr>
                        <td><Lang text={'minibus'} /></td>
                        <td>20</td>
                        <td>15</td>
                        <td>{price4} 000 <Lang text={'amd'} /></td>
                    </tr>
                </table>
            </div>
              </Accordion.Body>
            </Accordion.Item>
        </>
    );
};