import '../assets/css/AddReview/add_review.css';

export const AddReview = () => {

    const imageSelected = e => {
        if(document.getElementById('select_image')){
            document.getElementById('select_image').remove();
        }
        const forImage = document.getElementById('for_image');
        const oldImage = document.getElementById('view_image');
        const reader = new FileReader();
        reader.readAsDataURL(e.files[0]);
        if(!oldImage){
            const img = document.createElement('img');
            img.alt = 'photo';
            img.setAttribute('id', 'view_image');
            reader.addEventListener('load', k=>{
                const image = k.target.result;
                img.src = image;
                forImage.append(img);
            });
        }else{
            reader.addEventListener('load', k=>{
                const image = k.target.result;
                oldImage.src = image;
            });
        }
    };

    return (
        <>
            <div className="container add_review_page mt-10">
                <form action="../../adm/api/api.php" method="post" encType='multipart/form-data'>
                    <h2>Отзыв</h2>
                    <textarea name="review_text" placeholder='Отзыв' required></textarea>
                    <h2>Картинка <span>( Не обязательно )</span></h2>
                    <input type="file" accept="image/*" name="image" id="image" onChange={e=>{imageSelected(e.target)}} hidden />
                    <label for="image">
                        <div id="for_image"></div>
                        <div id="select_image">Выбрать картинку</div>
                    </label>
                    <h2>Количество звезд</h2>
                    <select name="count_star">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5" selected>5</option>
                    </select>
                    <br/>
                    <button type="submit" name='add_review'>Добавить отзыв</button>
                    <ul>
                        <li><h5>Отзыв будет добавлен на сайт через некоторое время</h5></li>
                    </ul>
                </form>
            </div>
        </>
    );
};