import { useState } from "react";

export const MinutePicker = ({ selectedMinute, onClick }) => {
  const minutes = ['00','05',10,15,20,25,30,35,40,45,50,55];
  const [menu, setMenu] = useState(false);

  return (
    <>
      <select onClick={() => setMenu(true)}>
        <option hidden>{selectedMinute === 60 || selectedMinute === 0 ? minutes[0] : selectedMinute === 5 ? minutes[1] : selectedMinute}</option>
      </select>

      {
        menu ? (
          <div className="date_menu hour">
            {minutes.map((minute) => (
              <div className={selectedMinute === minute ? 'selected_year' : null} key={minute} onClick={()=>{onClick(minute); setMenu(false)}}>
                {minute}
              </div>
            ))}
          </div>
        ) : null
      }
    </>
  );
};