import React from 'react';

export const DayPicker = ({ selectedDay, daysInMonth, onClick }) => {
  const days = Array.from(new Array(daysInMonth), (val, index) => index + 1);

  return (
    <div className="days">
      {days.map((day) => (
      <div className={selectedDay === day ? 'selected_day' : null} key={day} value={day} onClick={()=>onClick(day)}>
        {day}
      </div>
    ))}
    </div>
  );
};