import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { IoIosStar } from "react-icons/io";
// import garni from '../assets/images/garni_tachar.jpg';
import '../assets/css/Reviews/reviews.css';

export const Reviews = () => {
    const [reviews, setReviews] = useState([]);
    const [page, setPage] = useState(1);
    const [pageNumesAll, setPageNumesAll] = useState([]);
    
    useEffect(() => {
        const fetchReviews = async () => {
          try {
            const response = await axios.get(`/adm/api/api.php`, {
              params: {
                page: page
              }
            });
            setReviews(response.data);
          } catch (error) {
            console.error('Error fetching data: ', error);
          }
        };
    
        fetchReviews();
    }, [page]);

    useEffect(()=>{
        if(document.querySelector('.page_num')){
            const pageNumes = document.querySelectorAll('.page_num');
            pageNumes.forEach(item=>{
                item.classList.remove('page_selected_num');
            });
            document.getElementById('page_num_' + page).classList.add('page_selected_num');
        };
    });

    useEffect(() => {
        const fetchPageNumbers = async () => {
          try {
            const response = await axios.get('/adm/api/api2.php');
            const data = response.data;
            const totalPages = Math.ceil(data.count / 10);
            const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
            setPageNumesAll(pages);
          } catch (error) {
            console.error('Error fetching data count: ', error);
          }
        };
    
        fetchPageNumbers();
    }, []);

    return (
        <>
            <div className="container mt-10">
                <div className="add_review mb-3"><Link to='/add-review'>Добавить отзыв</Link></div>
                <h2 className="seo_text">
                    Прочитайте отзывы о нашей компании от довольных клиентов. Ваши отзывы важны для нас, и мы стремимся делать каждое путешествие незабываемым для наших гостей.
                    <br /><br />
                    Если у вас есть что сказать о нашей компании, пожалуйста, свяжитесь с нами, и мы с удовольствием опубликуем ваш отзыв на нашем сайте.
                </h2>

                {/* <div className="row mt-5">
                    <div className="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 review_contents mx-auto">
                        <img src={garni} alt="photo" />
                        <div className="review_box">
                        Давид очень вежливый , пунктуальный и аккуратный водитель. Чистая и удобная машина. Он встретил нас с аэропорта. На следующий день мы ездили в Дилижан, монастырь Агарцин и на озеро Севан. По дороге Давид угостил нас самым вкусным кофе, рассказывал про Армению, провел мини экскурсию по Монастырю, угостил гатой с чаем, посоветовал и отвёз в замечательный ресторан на Севане. Настоял на посещении пещеры дедушки Левона( за это особая благодарность!) А в Ереване мы поняли, что забыли дамскую сумочку в ресторане на Севане.... Но Давид решил и эту проблему. Мы остались гулять и к вечеру сумочка была у нас. Давид не только прекрасный водитель, он замечательный Человек!
                            <div className="stars">
                                <div className="active"><IoIosStar /></div>
                                <div className="active"><IoIosStar /></div>
                                <div className="active"><IoIosStar /></div>
                                <div className="active"><IoIosStar /></div>
                                <div className="active"><IoIosStar /></div>
                            </div>
                    </div>
                    </div>
                </div> */}
                
                {
                    reviews.map((data, index) => {
                        return (
                            <div className="row mt-5" key={index}>
                                <div className="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 review_contents mx-auto">
                                    {
                                        data.image ? <img src={`../../adm/images/${data.image}`} alt="photo" /> : null
                                    }
                                    <div className="review_box">
                                        {data.review_text}
                                        <div className="stars">
                                            <div className={data.count_star >= '1' ? "active" : null}><IoIosStar /></div>
                                            <div className={data.count_star >= '2' ? "active" : null}><IoIosStar /></div>
                                            <div className={data.count_star >= '3' ? "active" : null}><IoIosStar /></div>
                                            <div className={data.count_star >= '4' ? "active" : null}><IoIosStar /></div>
                                            <div className={data.count_star === '5' ? "active" : null}><IoIosStar /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                <div id="page_numes_all">
                    {
                        pageNumesAll.map((item, index) => {
                            return (
                                <div className="page_num" id={'page_num_' + item} onClick={() => setPage(item)} key={index}>{item}</div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    );
};
