import { ExcursionItem } from '../components/ExcursionItem';
import excursion1 from '../assets/images/excursion1.jpg';
import excursion2 from '../assets/images/excursion2.jpg';
import excursion3 from '../assets/images/excursion3.jpg';
import dilijan from '../assets/images/dilijan.jpeg';
import xor_virap from '../assets/images/xor_virap.jpeg';
import tatev from '../assets/images/tatev.jpg';
import zvartnots_tachar from '../assets/images/zvartnots_tachar.jpg';
import amberd from '../assets/images/amberd.jpg';
import haxpat from '../assets/images/haxpat.jpg';
import gyumri from '../assets/images/gyumri.webp';
import konyak1 from '../assets/images/konyak1.jpg';
import { Link } from 'react-router-dom';
import { Lang } from '../components/Lang';
import '../assets/css/Excursions/excursions.css';

export const Excursions = () => {
    return (
        <>
            <div className="container mt-10">
                <div className="mt-5">
                  Подарите себе незабываемое путешествие по сердцу Армении, где природа и древние памятники сливаются в одно целое. От величественной Арки Чаренца до древнего храма Гарни, от ущелья Симфонии Камней до таинственного монастыря Гегард – каждая остановка обещает удивление и вдохновение. Завершите день на мастер-классе по приготовлению лаваша и унесите с собой не только красивые фотографии, но и ароматные воспоминания об армянской культуре.
                </div>
                <ExcursionItem index={1} photo={excursion1} title={`
                  Арки Чаренца, Храма Гарни, Ущелья Симфонии Камней, Монастыря Гегард, Мастер-класса по лавашу
                `} text={`
                  Подарите себе незабываемое путешествие по сердцу Армении, где природа и древние памятники сливаются в одно целое. От величественной Арки Чаренца до древнего храма Гарни, от ущелья Симфонии Камней до таинственного монастыря Гегард – каждая остановка обещает удивление и вдохновение. Завершите день на мастер-классе по приготовлению лаваша и унесите с собой не только красивые фотографии, но и ароматные воспоминания об армянской культуре.
                `} />
                <ExcursionItem index={2} photo={excursion2} title={`
                  Арка Чаренца, Храм Гарни, Ущелье Симфония Камней, Монастырь Гегард, Озеро Севан и монастырь Севанаванк
                `} text={`
                  Эта увлекательная экскурсия по Армении приглашает вас на захватывающее путешествие через историю, культуру и природные красоты страны. Начиная с потрясающего вида с Арки Чаренца на долину и Арарат, вы сможете ощутить величие природы и уют древних мест. В древнем храме Гарни вы почувствуете древнее языческое наследие Армении, окруженные природой, способствующей внутреннему покою.
                `} />
                <ExcursionItem index={3} photo={dilijan} title={`
                  Озеро Севан и монастырь Севанаванк, Дилижан, Монастырский комплекс Агарцин, Монастырь Гошаванк
                `} text={`
                  Экскурсия по Северной Армении начинается с посещения кристально чистого озера Севан и древнего монастыря Севанаванк IX века. Затем мы направляемся в живописный Дилижан, "армянскую Швейцарию", славящуюся зелеными лесами и традиционной архитектурой. После этого осматриваем монастырский комплекс Агарцин X-XIII веков, расположенный в лесной долине. Завершаем путешествие в монастыре Гошаванк XII века, основанном ученым Мхитаром Гошем. Наслаждаясь видами и историей, мы погружаемся в атмосферу древности и духовного спокойствия.
                `} />
                <ExcursionItem index={4} photo={excursion3} title={`
                  Арка Чаренца, Храм Гарни, Ущелье Симфония Камней, Монастырь Гегард, Монастырь и крепость Хор Вирап
                `} text={`
                  Погрузитесь в уникальную красоту Армении: начните с Арки Чаренца с потрясающим видом на долину и Арарат. Затем посетите древний храм Гарни, окруженный живописной природой. В ущелье Симфония Камней удивитесь базальтовым колоннам, создающим волшебную атмосферу. Отправьтесь к монастырю Гегард, встречающему своим уникальным архитектурным стилем. Завершите экскурсию у монастыря Хор Вирап с видом на гору Арарат и его богатой историей.
                `} />
                <ExcursionItem index={5} photo={xor_virap} title={`
                  Хор Вирап, Пещера Птиц (Арени-1), Винный завод и дегустация вин, Нораванк
                `} text={`
                  Путешествие начинается с монастыря Хор Вирап с видом на гору Арарат. Затем — Пещера Птиц, где нашли древнейшую в мире винодельню. После экскурсии — тур по винодельческому заводу с дегустацией армянских вин. Завершение дня — монастырь Нораванк с его уникальной архитектурой и духовной атмосферой.
                `} />
                <ExcursionItem index={6} photo={tatev} title={`
                  Монастырь Нораванк, Монастырь Татев, Канатная дорога “Крылья Татева”, Село Хндзореск, Водопад Шаки
                `} text={`
                  Этот маршрут по югу Армении предлагает уникальное сочетание исторических, культурных и природных достопримечательностей. От древних монастырей и захватывающих дух видов с канатной дороги до уютных пещерных сел и живописных водопадов — каждое место на этом пути подарит вам незабываемые впечатления и глубокое погружение в богатую культуру и природу Армении.
                `} />
                <ExcursionItem index={7} photo={zvartnots_tachar} title={`
                  Храм Звартноц, Эчмиадзинский кафедральный собор, Церковь Св. Гаяне, Церковь Св. Рипсиме
                `} text={`
                  Этот тур по древним святыням Армении позволит вам прикоснуться к многовековой истории и культуре этой удивительной страны. Вы сможете насладиться величием древней архитектуры, почувствовать духовную атмосферу святых мест и понять, почему Армения считается колыбелью христианства.
                `} />
                <ExcursionItem index={8} photo={amberd} title={`
                  Крепость Амберд, Монастырь Сагмосаванк, Монастырь Ованаванк, Парк Армянского Алфавита
                `} text={`
                  Путешествие по этим историческим и культурным местам Армении откроет для вас богатую историю, духовное наследие и природную красоту этой удивительной страны. Каждое из этих мест предлагает уникальный взгляд на прошлое и настоящее Армении, позволяя полностью погрузиться в ее культуру и традиции. Крепость Амберд
                `} />
                <ExcursionItem index={9} photo={haxpat} title={`
                  Монастырь Ахпат, Пещерно-крепостной комплекс Зарни-Парни, Крепость Ахтала, Монастырь Санаин
                `} text={`
                  Этот маршрут по историческим и культурным достопримечательностям Армении подарит вам незабываемые впечатления и глубокое понимание богатого наследия этой удивительной страны. Окунитесь в прошлое, наслаждаясь красотой и загадочностью древних монастырей и крепостей, и откройте для себя магию Армении!
                `} />
                <ExcursionItem index={10} photo={gyumri} title={`
                  Гюмри
                `} text={`
                  Путешествие в Гюмри и посещение Черной крепости обещает незабываемые впечатления и возможность углубиться в богатую историю и культуру Армении, оставляя яркие воспоминания на долгие годы.
                `} />
                <ExcursionItem index={11} photo={konyak1} title={`
                  Обзорный тур Еревана дегустация конечно завода Арарат
                `} text={`
                  Ереван как столицу Армении, известную своей древней историей, архитектурой из розового туфа и богатым культурным наследием. Он подчеркивает туристическую привлекательность города благодаря многочисленным достопримечательностям, ночной жизни и кулинарным традициям. Также рассказывается о Коньячном Заводе АРАРАТ, который предлагает экскурсии по производству коньяка, позволяя гостям погрузиться в историю и насладиться уникальным вкусом этого напитка. Город Ереван:
                `} />

                <div className="mt-5">
                  <Link to='/gallery' className='link_a'><Lang text='gallery' /></Link>
                </div>
                <div className="mt-5">
                  Отправляйтесь в захватывающее путешествие с Big Voyage и откройте для себя великолепие и богатство культурного наследия Армении. Мы предлагаем разнообразные экскурсии, подходящие для каждого вкуса и интереса:
                </div>
                <div className="mt-5">
                  <h2>Исторические достопримечательности</h2>
                  <hr />
                  <ul>
                    <li>Погрузитесь в древнюю историю Армени посетив древние храмы, крепости, монастыри и археологические памятники, которые восхищают своей архитектурой и величием.</li>
                  </ul>
                </div>
                <div className="mt-5">
                  <h2>Природные удивления</h2>
                  <hr />
                  <ul>
                    <li>Откройте для себя уникальные природные ландшафты Армении , включая горные вершины, озера, водопады и каньоны, которые вызывают восхищение и восторг.</li>
                  </ul>
                </div>
                <div className="mt-5">
                  <h2>Винные туры</h2>
                  <hr />
                  <ul>
                    <li>Отведайте изысканные вина и насладитесь красотой винодельческих регионов Армении , известных своими вековыми традициями производства вина.</li>
                  </ul>
                </div>
                <div className="mt-5">
                  <h2>Многодневные путешествия</h2>
                  <hr />
                  <ul>
                    <li>Планируйте захватывающие многодневные туры по различным регионам Армении  и окунитесь в атмосферу удивительных приключений и открытий.</li>
                  </ul>
                </div>
                <div className="mt-6 mb-5">
                  Независимо от выбранного вами направления, наши опытные гиды помогут вам раскрыть все тайны и красоты этих удивительных стран. Погрузитесь в атмосферу приключений и откройте для себя новые горизонты вместе с Big Voyage!
                </div>
            </div>
        </>
    );
};