import { FaPhoneAlt } from "react-icons/fa";
import gmail from '../assets/images/gmail.png';
import facebook from '../assets/images/facebook.png';
import instagram from '../assets/images/instagram.webp';
import '../assets/css/ContactUs/contactUs.css';

export const ContactUs = () => {
    return (
        <>
            <div className="container mt-10">
              Свяжитесь с нами, чтобы узнать больше о наших услугах или оставить заявку. Мы всегда готовы помочь вам с планированием вашего следующего путешествия и ответить на все ваши вопросы.
              <div className="mt-5">
                <h2>Контактная информация</h2>
                <hr />
                <div className="contacts">
                    <div className="contact_block">
                        <div className="contact_icon"><FaPhoneAlt /></div>
                        <div className="contact_text">
                            <a href="tel:+37444433030">+374 44 43 30 30</a>
                        </div>
                    </div>
                    <div className="contact_block">
                        <div className="contact_icon"><img src={gmail} alt="social media" /></div>
                        <div className="contact_text">
                            <a href="mailto:big-voyage@mail.ru" target="_blank">big-voyage@mail.ru</a>
                        </div>
                    </div>
                    <div className="contact_block">
                        <div className="contact_icon"><img src={facebook} alt="social media" /></div>
                        <div className="contact_text">
                        {/* https://www.facebook.com/Transfer.Erevan.Tbilisi */}
                            <a href="#" target="_blank">Facebook</a>
                        </div>
                    </div>
                    <div className="contact_block">
                        <div className="contact_icon"><img src={instagram} alt="social media" /></div>
                        <div className="contact_text">
                        {/* https://www.instagram.com/big.voyage/ */}
                            <a href="#" target="_blank">Instagram</a>
                        </div>
                    </div>
                </div>
                <div className="mt-10 mb-5">
                  Не стесняйтесь связаться с нами, и мы с удовольствием поможем вам с вашими путешествиями!
                </div>
              </div>
            </div>
        </>
    );
};