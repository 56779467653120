import { useState, useEffect, useRef, useCallback } from 'react';
import { Lang } from '../components/Lang';
import sedan from '../assets/images/sedan.png';
import comfort from '../assets/images/comfort.png';
import minivan from '../assets/images/minivan.png';
import minibus from '../assets/images/minibus.png';
import { FaUser } from "react-icons/fa";
import { IoBag } from "react-icons/io5";
import { PiSeatFill } from "react-icons/pi";
import { FaCaretDown } from "react-icons/fa";
import child_seats1 from '../assets/images/child_seats_1.svg';
import child_seats2 from '../assets/images/child_seats_2.svg';
import child_seats3 from '../assets/images/child_seats_3.svg';
import user_name_table from '../assets/images/user_name_table.svg';
import comment from '../assets/images/comment.svg';
import { MdAlternateEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { Calendar } from '../components/calendar/Calendar';
import { LoadScript, GoogleMap, Autocomplete, Marker } from '@react-google-maps/api';
import emailjs from 'emailjs-com';
import '../assets/css/BookNow/bookNow.css';


export const BookNow = () => {
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [selectedCar, setSelectedCar] = useState(0);
  const [returnRoute, setReturnRoute] = useState(false);
  const [adults, setAdults] = useState(1);
  const [childSeats, setChildSeats] = useState(false);
  const [childSeats1, setChildSeats1] = useState(0);
  const [childSeats2, setChildSeats2] = useState(0);
  const [childSeats3, setChildSeats3] = useState(0);
  const [inputPlace, setInputPlace] = useState('Имя на табличке, которую будет держать водитель');
  const activeInput = useRef(null);
  const [disabledReturn, setDisabledReturn] = useState(false);
  const [cost, setCost] = useState(0);
  const [calendar1, setCalendar1] = useState(null);
  const [calendar2, setCalendar2] = useState(null);

  const libraries = ['places']; // Убедитесь, что библиотека 'places' загружена

  const [fromAddress, setFromAddress] = useState('');
  const [toAddress, setToAddress] = useState('');

  const fromAutocompleteRef = useRef(null);
  const toAutocompleteRef = useRef(null);

  const [map, setMap] = useState(null);
  const [fromMarker, setFromMarker] = useState(null);
  const [toMarker, setToMarker] = useState(null);
  const [linePath, setLinePath] = useState([]);
  const polylineRef = useRef(null);

  const calculateDistance = async () => {
    if (!fromAddress || !toAddress) return;
  
    try {
      const google = window.google;
  
      if (google) {
        const directionsService = new google.maps.DirectionsService();
  
        directionsService.route(
          {
            origin: fromAddress,
            destination: toAddress,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              const route = result.routes[0];
              if (route) {
                const legs = route.legs[0];
                setDistance(Math.floor(legs.distance.value / 1000));
                setDuration(legs.duration.text);
              } else {
                setDistance('Unable to calculate distance');
              }
            } else {
              setDistance('Error retrieving distance');
            }
          }
        );
      }
    } catch (error) {
      console.error('Error fetching distance:', error);
      setDistance('Error fetching distance');
    }
  };  

  const onLoadMap = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const handlePlaceChanged = useCallback((setAddress, setMarker, type) => () => {
    const autocomplete = (type === 'from') ? fromAutocompleteRef.current : toAutocompleteRef.current;
    const place = autocomplete.getPlace();
    if (place.geometry) {
      const location = place.geometry.location;
      setAddress(place.formatted_address);
      setMarker({ lat: location.lat(), lng: location.lng() });

      if (map) {
        map.panTo(location);
        map.setZoom(14);
      }
    }
  }, [map, fromMarker, toMarker]);

  useEffect(() => {
    if (fromMarker && toMarker) {
      setLinePath([fromMarker, toMarker]);
    }
    calculateDistance();
  }, [fromMarker, toMarker]);

  useEffect(() => {
    if (fromMarker && toMarker) {
      // Очистите старую линию, если она существует
      if (polylineRef.current) {
        polylineRef.current.setMap(null);
      }

      const newPolyline = new window.google.maps.Polyline({
        path: [fromMarker, toMarker],
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 4,
        clickable: false,
        editable: false,
        geodesic: true,
      });

      newPolyline.setMap(map);
      polylineRef.current = newPolyline;
    };
  }, [fromMarker, toMarker, map]);

  useEffect(()=>{
    if(localStorage.getItem('isTour') === 'true'){
      setToAddress(localStorage.getItem('tourTitle'));
      setDisabledReturn(true);
    };
    setTimeout(()=>{
      if(!document.getElementById('from_input')){
        window.location.reload();
      };
    }, 500);
  }, []);

  useEffect(()=>{
      if(selectedCar === 1){
        if(distance <= 50){
          setCost(distance * 350);
        } else if(distance <= 101){
            setCost(50 * 350 + (distance - 50) * 250);
        } else{
            setCost(50 * 350 + 50 * 250 + (distance - 100) * 150);
        };
      } else if(selectedCar === 2){

      } else if(selectedCar === 3){
        
      } else if(selectedCar === 4){
        
      }
  }, [distance, selectedCar]);

  useEffect(()=>{
    const minCost = 7000;
    if(cost < minCost) {
      setCost(minCost);
    };
  }, [cost]);

  const handleClickOutside = (event) => {
    if (activeInput.current && !activeInput.current.contains(event.target)) {
      setInputPlace('Имя на табличке, которую будет держать водитель');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // send data
  let carClass;
  if(selectedCar === 1){
    carClass = 'Эконом';
  }else if(selectedCar === 2){
    carClass = 'Комфорт';
  }else if(selectedCar === 3){
    carClass = 'Минивэн';
  }else if(selectedCar === 4){
    carClass = 'Микроавтобус';
  }
  const [userTableName, setUserTableName] = useState('');
  const [userComment, setUserComment] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if(selectedCar === 0){
      alert(Lang({text: 'select_car_type'}));
      return;
    }

    const templateParams = {
      from_name: email,
      to_name: email,
      subject: 'Забронирование',
      message: {
        where_location: fromAddress !== '592X+QPJ, Ереван, Армения' ? fromAddress : 'Международный Аэропорт “Звартноц” (EVN) Ереван, Армения',
        to_location: toAddress !== '592X+QPJ, Ереван, Армения' ? toAddress : 'Международный Аэропорт “Звартноц” (EVN) Ереван, Армения',
        car_class: carClass,
        travel_date: calendar1,
        return_travel: returnRoute ? calendar2 : '',
        users_count: adults,
        baby1: childSeats1,
        baby2: childSeats2,
        baby3: childSeats3,
        user_table_name: userTableName,
        comment: userComment,
        email: email,
        phone: phone,
        cost: returnRoute && cost !== 7000 ? Math.ceil(cost.toFixed(2))-Math.ceil(cost.toFixed(2)) *10 / 100 : Math.ceil(cost.toFixed(2))
      },
    };

    emailjs.send('service_u45663r', 'template_96nja2u', templateParams, 'gpqUPLO47KyRtR_b-')
      .then((response) => {
        console.log(response.status, response.text);
        window.location.reload();
      }, (error) => {
        console.log(error.text);
        alert('есть проблема, проверьте всю информацию');
      });
  };

    return (
        <>
            <div className="container mt-11">
              <form className="m-0 p-0" onSubmit={handleSubmit}>
            <div id="input_map">
            <div className='addres_filter'>
      <LoadScript
      googleMapsApiKey="AIzaSyB3kJEhNtV0xvvEsBMJsQJ0o9Sbi832t3k" // Замените на ваш API ключ
      libraries={libraries}
    >
      <div className='m-0 p-0'>
      <div className="input_clear m-0 p-0">
        <Autocomplete
          onLoad={(autocomplete) => (fromAutocompleteRef.current = autocomplete)}
          onPlaceChanged={handlePlaceChanged(setFromAddress, setFromMarker, 'from')}
          className='m-0 p-0'
        >
          <input
            type="text"
            placeholder={Lang({text: 'from_where'})}
            onChange={e => setFromAddress(e.target.value)}
            value={fromAddress}
            required
            id='from_input'
          />
        </Autocomplete>
        <div className="clear_filter" onClick={()=>{
        document.getElementById('from_input').value = '';
        setFromAddress('');
        setDistance(null);
        setDuration(null);
        if (polylineRef.current) {
          polylineRef.current.setMap(null);
        }
      }}>X</div>
        </div>
        <hr />
        <div className="input_clear m-0 p-0 mb-3">
        <Autocomplete
          onLoad={(autocomplete) => (toAutocompleteRef.current = autocomplete)}
          onPlaceChanged={handlePlaceChanged(setToAddress, setToMarker, 'to')}
          className='m-0 p-0'
        >
          <input
            type="text"
            placeholder={Lang({text: 'where_to'})}
            onChange={e => setToAddress(e.target.value)}
            value={toAddress}
            required
            id='to_input'
          />
        </Autocomplete>
        {
        !disabledReturn ? <div className="clear_filter" onClick={()=>{
          document.getElementById('to_input').value = '';
          setToAddress('');
          setDistance(null);
          setDuration(null);
          if (polylineRef.current) {
            polylineRef.current.setMap(null);
          }
        }}>X</div>
        : null
      }
        </div>
      </div>
    </LoadScript>
      <div className="car_clases_box">
      <div className={selectedCar === 1 ? 'car_class selected_car' : 'car_class'} onClick={()=>{setSelectedCar(1)}}>
        <img src={sedan} alt="car photo" />
        <span className='title_icons'>
          <Lang text='simple_sedan' />
          <span><FaUser /> x 3 <IoBag /> x 3</span>
        </span>
      </div>
      <div className={selectedCar === 2 ? 'car_class selected_car' : 'car_class'} onClick={()=>{setSelectedCar(2)}}>
        <img src={comfort} alt="car photo" />
        <span className='title_icons'>
          <Lang text='comfort' />
          <span><FaUser /> x 4 <IoBag /> x 3</span>
        </span>
      </div>
      </div>
      <div className="car_clases_box">
      <div className={selectedCar === 3 ? 'car_class selected_car' : 'car_class'} onClick={()=>{setSelectedCar(3)}}>
        <img src={minivan} alt="car photo" />
        <span className='title_icons'>
          <Lang text='minivan' />
          <span><FaUser /> x 7 <IoBag /> x 7</span>
        </span>
      </div>
      <div className={selectedCar === 4 ? 'car_class selected_car' : 'car_class'} onClick={()=>{setSelectedCar(4)}}>
        <img src={minibus} alt="car photo" />
        <span className='title_icons'>
          <Lang text='minibus' />
          <span><FaUser /> x 20 <IoBag /> x 15</span>
        </span>
      </div>
      </div>
      <div className="travel_infos">
        <h2 className="seo_text_second">
        <label htmlFor="date_time_travel">
          Выберите дату и время начала поездки
        </label>
        </h2>
        <Calendar setState={setCalendar1} />
          {
            disabledReturn ? null : <div className='return_route_parent p-0'>
            <input type="checkbox" id="return_route" onClick={()=>{setReturnRoute(!returnRoute)}}/> Добавить обратный маршрут<span className='px-1'>|</span><span className='text-danger'>Скидка 10%</span>
          </div>
          }
        {
          returnRoute ? <div className='mt-3 p-0'>
            <label htmlFor="date_time_return">
              Выберите дату и время возвращения
            </label>
            <Calendar setState={setCalendar2} />
          </div> : null
        }
        <div className="travel_info_icons p-0">
        <div className="travel_info_icons_left p-0">
        <FaUser /> Взрослые
        </div>
        <input type="number" min='1' max='20' value={adults} name="adults" readOnly required onChange={(e)=>{
          setAdults(e.target.value);
        }}/>
        <div className="travel_info_icons_right p-0">
          <span onClick={()=>{
            if(adults !== 1){
              setAdults(adults-1);
            }
          }}>-</span>
          <span onClick={()=>{
            if(adults !== 20){
              setAdults(adults+1);
            }
          }}>+</span>
        </div>
        </div>
        <div className="child_seats p-0" onClick={()=>{setChildSeats(true)}}>
          <div>
            <PiSeatFill />
            {
              childSeats1 || childSeats2 || childSeats3 > 0 ? null : 'Детские кресла'
            }
            <span className="selected_childs_seats">
              {
                childSeats1 > 0 ? <span><img src={child_seats1} alt="photo"/> x {childSeats1}</span> : null
              }
              {
                childSeats2 > 0 ? <span><img src={child_seats2} alt="photo"/> x {childSeats2}</span> : null
              }
              {
                childSeats3 > 0 ? <span><img src={child_seats3} alt="photo"/> x {childSeats3}</span> : null
              }
            </span>
          </div>
          <div><FaCaretDown /></div>
        </div>
        <div className="user_name_table p-0">
          <img src={user_name_table} className={inputPlace === '' ? 'user_name_table_image' : null} alt="user name" />
          {
            inputPlace === '' ? 'Имя на табличке, которую будет держать водитель' : null
          }
          <input type="text" placeholder={inputPlace} ref={activeInput} name='user_name_table' required onClick={()=>{
            setInputPlace('');
          }} onChange={e=>{
            setUserTableName(e.target.value);
          }}/>
        </div>
        <div className="user_name_table p-0">
          <img src={comment} alt="comment info" />
          <textarea placeholder='Информация о багаже, особенные требования или задание для водителя' name="comment_info" onChange={e=>{
            setUserComment(e.target.value);
          }}></textarea>
        </div>
        <div className="user_name_table p-0">
          <MdAlternateEmail />
          <input type="email" placeholder='Адрес эл. почты' required onChange={e=>{
            setEmail(e.target.value);
          }}/>
        </div>
        <div className="user_name_table p-0">
          <FaPhone />
          <input type="number" placeholder='Номер телефона' required onChange={e=>{
            setPhone(e.target.value);
          }}/>
        </div>
        {
          childSeats ? <div className="child_seats_big_box" onClick={(e)=>{
            if(e.target.getAttribute('class') === 'child_seats_big_box'){
              setChildSeats(false);
            }
          }}>
            <div className="child_seats_box">
              <div className="child_seats_item">
                <div className='child_seats_close'>Детские кресла</div>
                <div className='child_seats_close' onClick={()=>{
                  setChildSeats(false);
                }}>X</div>
              </div>
              <div className="child_seats_item">
                <div className="child_seats_two_item">
                  <img src={child_seats1} alt="photo"/>
                  <div className='child_seats_title'>
                    Автолюлька
                    <br />
                    До 10 кг, до 6 месяцев
                  </div>
                </div>
                <div className="child_seats_two_item">
                  <div className="child_seats_buttons p-0">
                    <span onClick={()=>{
                      if(childSeats1 !== 0){
                        setChildSeats1(childSeats1-1);
                      }
                    }}>-</span>
                    <input type="text" value={childSeats1} name="child_seats1" readOnly/>
                    <span onClick={()=>{
                      if(childSeats1 !== 20){
                        setChildSeats1(childSeats1+1);
                      }
                    }}>+</span>
                  </div>
                </div>
              </div>
              <div className="child_seats_item">
                <div className="child_seats_two_item">
                  <img src={child_seats2} alt="photo"/>
                  <div className='child_seats_title'>
                    Автокресло
                    <br />
                    9–25 кг, 0–7 лет
                  </div>
                </div>
                <div className="child_seats_two_item">
                <div className="child_seats_buttons p-0">
                    <span onClick={()=>{
                      if(childSeats2 !== 0){
                        setChildSeats2(childSeats2-1);
                      }
                    }}>-</span>
                    <input type="text" value={childSeats2} name="child_seats2" readOnly/>
                    <span onClick={()=>{
                      if(childSeats2 !== 20){
                        setChildSeats2(childSeats2+1);
                      }
                    }}>+</span>
                  </div>
                </div>
              </div>
              <div className="child_seats_item">
                <div className="child_seats_two_item">
                  <img src={child_seats3} alt="photo"/>
                  <div className='child_seats_title'>
                    Бустер
                    <br />
                    22–36 кг, 6–12 лет
                  </div>
                </div>
                <div className="child_seats_two_item">
                <div className="child_seats_buttons p-0">
                    <span onClick={()=>{
                      if(childSeats3 !== 0){
                        setChildSeats3(childSeats3-1);
                      }
                    }}>-</span>
                    <input type="text" value={childSeats3} name="child_seats3" readOnly/>
                    <span onClick={()=>{
                      if(childSeats3 !== 20){
                        setChildSeats3(childSeats3+1);
                      }
                    }}>+</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null
        }
      </div>
    </div>
    <div id='map_parent'>
    <LoadScript
        googleMapsApiKey="AIzaSyB3kJEhNtV0xvvEsBMJsQJ0o9Sbi832t3k" // Replace with your API key
        libraries={libraries}
    >
       <GoogleMap
          center={fromMarker || toMarker || { lat: 40.1772, lng: 44.5035 }} // Center map based on markers
          zoom={13}
          onLoad={onLoadMap}
          id='map'
        >
          {fromMarker && <Marker position={fromMarker} />}
          {toMarker && <Marker position={toMarker} />}
        </GoogleMap>
      </LoadScript>
      {distance !== null ? <>
        <div className="distance-info text-center mt-3">
          <span>Расстояние</span>: {distance} км ~ {duration !== null ? `${duration}` : 'Загрузка...'}
          <br />
          <span>Стоимость поездки</span>: {returnRoute && cost !== 7000 ? Math.ceil(cost.toFixed(2))-Math.ceil(cost.toFixed(2)) *10 / 100 : Math.ceil(cost.toFixed(2))} ֏
      </div>
      </> : null}
    </div>
            </div>
            <div className="book_now_btn mb-5">
              <button type='submit'>Забронировать</button>
            </div>
            </form>
            </div>
        </>
    )
};